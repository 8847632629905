import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import {ReactSession} from "react-client-session";
const Navbar=()=>{
    const [isLogin,setIsLogin]=useState(false);
    ReactSession.setStoreType("localStorage");
    useEffect(()=>{
        if(ReactSession.get("student_id")!==null || ReactSession.get("student_id")!==undefined){
            setIsLogin(true);
        }
        else{
            setIsLogin(false);
        }
    },[])
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">Student Admission Data Portal</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarColor01">
                        {/*<ul className="navbar-nav me-auto">*/}
                        {/*    <li className="nav-item">*/}
                        {/*        <a className="nav-link active" href="/">Home*/}
                        {/*            <span className="visually-hidden">(current)</span>*/}
                        {/*        </a>*/}
                        {/*    </li>*/}
                        {/*    <li className="nav-item">*/}
                        {/*        <a className="nav-link" href="/student">Student</a>*/}
                        {/*    </li>*/}
                        {/*    {isLogin?*/}
                        {/*        <li className="nav-item">*/}
                        {/*            <a className="nav-link" href="/student/dashboard">My Dashboard</a>*/}
                        {/*        </li>:*/}
                        {/*        <li></li>*/}
                        {/*    }*/}
                        {/*    <li className="nav-item">*/}
                        {/*        <a className="nav-link" href="/college">Colleges</a>*/}
                        {/*        /!*<Link to="/newApp" replace>Colleges</Link>*!/*/}
                        {/*    </li>*/}

                        {/*</ul>*/}

                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;