import React,{useState} from "react";
import Axios from 'axios';
import {useHistory} from "react-router-dom";


const ForgotPassword=()=>{
    const history=useHistory();
    let eleName,value;
    const [student,setStudent]=useState({
        student_mobile:"",student_email:"",
    });

    const handleInputs=(e)=>{
        e.preventDefault();
        eleName=e.target.name;
        value=e.target.value;
        setStudent({...student,[eleName]:value});
    }


    const postGetPassword=async (e)=>{
    //get the student details by email and mobile
        e.preventDefault();
        // const student1=await Axios.post(`${process.env.REACT_APP_URI}student/detail/one`, {
        await Axios.post(`${process.env.REACT_APP_URI}student/detail/one`, {
            student_mobile:student.student_mobile,
            student_email:student.student_email,
            student_password:student.student_password
        }).then((res)=>{
            console.log(res.data)
            if(res.data.isUpdated)
            {
                alert(res.data.message+". Please try to login.");
                // if(window.confirm('Click ok to login to system')){
                    history.push('/');
                // }else{
                //     return;
                // }
            }else{
                alert('Password cannot be changed. Either email or phone number is incorrect.')
            }
        }).catch(err=>{
            console.log(err.message);
        });

    }

    return(
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">&nbsp;</div>
                    <div className="col-md-6">
                        <div className="card border-secondary mt-3 text-justify">
                            <div className="card-header"><h4 className="card-title"> Forgot Password</h4></div>
                            <div className="card-body">
                                <div className="container form-floating">
                                    <form method={'POST'} id="form-studentbasic">
                                        <div className="row">

                                            <div className="col-md-12 ">
                                                <label className="mt-3">Email ID</label>
                                                <input type="email" name="student_email" className="form-control"
                                                       value={student.student_email} onChange={handleInputs}
                                                />
                                            </div>
                                            <div className="col-md-12 ">
                                                <label className="mt-3">Mobile</label>
                                                <input type="text" name="student_mobile" className="form-control"
                                                       value={student.student_mobile} onChange={handleInputs}
                                                />
                                            </div>
                                            <div className="col-md-12 ">
                                                <label className="mt-3">New Password</label>
                                                <input type="password" name="student_password" className="form-control"
                                                       value={student.student_password} onChange={handleInputs}
                                                />
                                            </div>


                                            <button type={"button"} onClick={postGetPassword} className="btn btn-sm btn-success mt-3 mx-2" > Change Password</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">&nbsp;</div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;