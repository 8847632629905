import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Axios from "axios";
import {ReactSession} from "react-client-session";
import ArrowHeader from "../arrowHeader";
import {IsValidLogin} from "../isLoggedIn";
import dateFormat from "dateformat";
// import {useHistory} from "react-router";
// import {withRouter} from "react-router-dom";

const ConfirmDetail=props=>{
    const history=useHistory();
    ReactSession.setStoreType("localStorage");

    const [appr,setAppr]=useState(false)
    const getApproval=async (stream_id)=>{
        //Approval works only for the ARTS Stream
        const aprroval=await Axios.get(`${process.env.REACT_APP_URI}student/approved/applicationid/${ReactSession.get("student_id")}`).then((res)=>{
            console.log("Appr",stream_id)
            setAppr(res.data.isAllowed)
        })
    }

    useEffect(()=>{
        ReactSession.set("stream_id",props.match.params.strid);
        if(!IsValidLogin()){
            // console.log("entered");
            localStorage.clear();
            history.push("/");
        }
        alert("Please check the details afterwards no change will be allowed.")
        getApproval(ReactSession.get("stream_id"));
        getStudentBasic();
        getCategories();
        getStudentDetail();
        getSubjectsOptional();
        getSubjectsOptional1();
        getAdmissionStatus();

        // alert(student.admission_data)

    },[])
    const [student,setStudent]=useState({
        student_name:"",student_fathername:"",student_dob:"",student_gender:"",
        student_mobile:"",student_email:"",student_batch:"",student_id:0,admission_data:false
    });

    const [admissionStatus, setAdmissionStatus]=useState(false)
    const [docStatus,setDocStatus]=useState(false)
    const getAdmissionStatus=async ()=>{
        const student_id=ReactSession.get("student_id");
        const docs=await Axios.get(`${process.env.REACT_APP_URI}student/doc/detail/${ReactSession.get("student_id")}`)
            .then((re)=>{
                setDocStatus(true)
            });
        const admission=await Axios.get(`${process.env.REACT_APP_URI}student/get/admission/fee/status/${ReactSession.get("student_id")}/${ReactSession.get("college_id")}/${props.match.params.strid}`)
            .then((res)=>{
                //

                if((res.data.isPaidAdmFee))
                {
                    setAdmissionStatus(true)
                    ReactSession.set("admission_status",true)
                    ReactSession.set("stream_id",props.match.params.strid)
                    if(appr) {
                        history.push(`/print/admission/form/${ReactSession.get("student_id")}/${ReactSession.get("college_id")}/${ReactSession.get("stream_id")}`)
                    }else{
                        history.push('/student/dashboard');
                    }
                }
            }).catch(err=>{
                //alert('Some Error Occured')
                console.log(err.message);
            })
        //alert(docStatus)
        // if(await docs.length>0){
        //
        // }
    }
    // const isApproved=async ()=>{
    //     const statuss=await Axios.get(`${process.env.REACT_APP_URI}student/get/status/data/${ReactSession.get("student_id")}`)
    //     if(statuss.isapproved){
    //         history.push('/')
    //     }
    // }

    const [optionalsubjects,setOptionalSubjects]=useState([{}]);
    const getSubjectsOptional=async ()=>{
        await Axios.get(`${process.env.REACT_APP_URI}subject/select/OPTIONAL/${ReactSession.get("stream_id")}`).then((res)=>{
            console.log(res.data)
            setOptionalSubjects(res.data);
        }).catch(err=>{
            console.log(err.message);
        })
    }
    const [opt1,setOpt1]=useState({});
    const getSubjectsOptional1=async ()=>{
        await Axios.get(`${process.env.REACT_APP_URI}subject/select-optional/${ReactSession.get("student_id")}/${ReactSession.get("stream_id")}`).then((res)=>{
            console.log("list:=",res.data[0])
            setOpt1(res.data[0]);
        }).catch(err=>{
            console.log(err.message);
        })
    }
    const[studentd,setStudentd]=useState({
        student_mothername:"", student_caste:"",student_address:"",student_aadhar:"",
        student_pwd:"",student_nationality:"",student_religion:"",optional1_id:0,
    })
    //let errorsObj={stduent_email:'',student_password:''};
    //const [errors,setErrors]=useState(errorsObj);

    let eleName,value;
    const handleInputs=(e)=>{
        eleName=e.target.name;
        value=e.target.value.toUpperCase();
        setStudentd({...studentd,[eleName]:value});
    }

    const handleInput=(e)=>{
        eleName=e.target.name;
        value=e.target.value.toUpperCase();
        setStudent({...student,[eleName]:value});
    }
    const[categories,setCategories]=useState([{}]);
    const getCategories=async ()=>{
        const allCastes=await Axios.get(`${process.env.REACT_APP_URI}caste/category`).then((res)=>{
            setCategories(res.data);
        }).catch(err=>{
            console.log(err.message);
        });
        console.log(categories);
    }
    const getStudentBasic=async ()=>{
        const student_id=ReactSession.get("student_id");
        const student1=await Axios.get(`${process.env.REACT_APP_URI}student/byid/${student_id}`).then((res)=>{
            setStudent(res.data);
            // alert(res.data.admission_data)
            if(res.data.admission_data!==null && res.data.admission_data===true){
                history.push('/student/upload/proof')
            }
            console.log(res.data);
        });

        console.log(student);
    }

    const getStudentDetail=async ()=>{
        const student_id=ReactSession.get("student_id");
        const student1=await Axios.get(`${process.env.REACT_APP_URI}student/detail/id/${student_id}`).then((res)=>{
            setStudentd(res.data);
            console.log(res.data);
        });
        console.log(student);
    }



    const postStudentDetail=async (e)=>{
        var response={};
        e.preventDefault();
        var confirm1=window.confirm("Are you sure to update the information?");
        if(!confirm1){
            return;
        }
        console.log(studentd)
        const {student_name,student_fatherName,student_dob,student_gender,
            student_mobile,student_email,student_batch}=student;



        const res=await Axios.put(`${process.env.REACT_APP_URI}student/confirm/detail/${ReactSession.get("student_id")}`,{
            //student_id:ReactSession.get("student_id"),
            student_fatherName:student.student_fatherName,
            student_dob:student.student_dob,
            studend_mobile:student.student_mobile,
            admission_data:true,
        },{

            headers:{
                "Content-Type":"application/json",
            },
        }).then((res)=>
        {
            response=res.data;
            console.log(res);
            ReactSession.setStoreType("localStorage");
            ReactSession.set("session_status","Active");
            ReactSession.set("session_role","Student");
            // ReactSession.set("student_email",student.student_email);
        });

        const res1=await Axios.put(`${process.env.REACT_APP_URI}student/detail/update/${ReactSession.get("student_id")}`,{
            //student_id:ReactSession.get("student_id"),
            student_mothername :studentd.student_mothername,
            student_aadhar:studentd.student_aadhar,
        },{

            headers:{
                "Content-Type":"application/json",
            },
        }).then((res)=>
        {
            response=res.data;
            console.log(res);
            ReactSession.setStoreType("localStorage");
            ReactSession.set("session_status","Active");
            ReactSession.set("session_role","Student");
            // ReactSession.set("student_email",student.student_email);
        }).catch(err=>{
            console.log(err.message)
        });

        const resop=await Axios.put(`${process.env.REACT_APP_URI}student/optional/update/${ReactSession.get("student_id")}`,{
            //student_id:ReactSession.get("student_id"),
            optional1_id:studentd.optional1_id
        },{

            headers:{
                "Content-Type":"application/json",
            },
        }).then((res)=>
        {
            response=res.data;
            console.log(res);
            // ReactSession.set("student_email",student.student_email);
        }).catch(err=>{
            console.log(err.message)
        });

        console.log(response);
        if(!response || response.status>=400){
            console.log(response);
            alert("Please check the form again");
        }else{

                alert("Information updated Successfully");

            history.push("/student/upload/proof");
        }

    }
    return(
        <>
            <ArrowHeader form="detail"  />
            <div className="container">
                <div className="row">
                    <div className="col-md-1">&nbsp;</div>
                    <div className="col-md-10">
                        <div className="card border-secondary mt-3 text-justify">
                            <div className="card-header"><h4 className="card-title"> Student's Personal Details</h4></div>
                            <div className="card-body">
                                <div className="alert alert-dismissible alert-primary">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert"></button>
                                    Please check your details carefully. <strong>No further change will be allowed after
                                the confirmation.</strong>
                                </div>
                                <div className="container form-floating">
                                    <form method="POST" id="form-studentdetail" onSubmit={postStudentDetail}>
                                        <div className="row">
                                            <div className="col-md-6 ">
                                                <label>Student Name</label>
                                                <input type="text" name="student_name" className="form-control"
                                                       value={student.student_name} onChange={handleInputs} readOnly
                                                />
                                            </div>
                                            <div className="col-md-6 ">
                                                <label >Father's Name</label>
                                                <input type="text" name="student_fatherName" className="form-control"
                                                       value={student.student_fatherName} onChange={handleInput}
                                                />
                                            </div>
                                            <div className="col-md-6 ">
                                                <label className="mt-3">Mother's Name</label>
                                                <input type="text" name="student_mothername" className="form-control"
                                                       value={studentd.student_mothername} onChange={handleInputs}
                                                       pattern="[a-zA-Z'-'\s]*" required={true}
                                                />
                                            </div>
                                            {/*get the list of caste*/}




                                            <div className="col-md-3 ">
                                                <label className="mt-3">Aadhar Number</label>
                                                <input type="text" name="student_aadhar" className="form-control"
                                                       value={studentd.student_aadhar} onChange={handleInputs}
                                                       minLength={12} maxLength={12}
                                                />
                                            </div>
                                            {
                                                // alert(student.student_dob)
                                            }
                                            <div className="col-md-3 ">
                                                <label className="mt-3">Date of Birth</label>
                                                <input type="date" name="student_dob" className="form-control" max="2010-01-01"
                                                       value={dateFormat(student.student_dob,"yyyy-mm-dd")} onChange={handleInput}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="mt-3">Address</label>
                                                <textarea  name="student_address" className="form-control"
                                                           value={studentd.student_address} onChange={handleInputs}
                                                           required readOnly
                                                ></textarea>
                                            </div>



                                            <div className="col-md-2 ">
                                                <label className="mt-3">Mobile No.</label>
                                                <input type="text" name="student_mobile" className="form-control"
                                                       value={student.student_mobile} onChange={handleInputs} readOnly={true}
                                                />
                                            </div>
                                            <div className="col-md-3 ">
                                                <label className="mt-3">Email ID</label>
                                                <input type="email" name="student_email" className="form-control"
                                                       value={student.student_email} onChange={handleInputs} readOnly={true}
                                                />
                                            </div>

                                            <div className="col-md-6 ">
                                                <label>Optional Subject : <small>Current: {opt1.subject_name}</small></label>
                                                <select  name="optional1_id" id="optional1_id" className="form-control"
                                                    onChange={handleInputs}
                                                         required={true}
                                                >
                                                    <option value="">--Select Subject--</option>
                                                    { optionalsubjects.length>0?
                                                        optionalsubjects.map(item => {
                                                            ///   console.log(item);
                                                            return <option key={item.subject_id} value={item.subject_id}> {item.subject_name} ({item.subject_code})</option>
                                                        }): <option > No Subjects Found</option>
                                                    }
                                                </select>
                                            </div>

                                            {
                                                appr?
                                                    <button type={"submit"} className="btn btn-sm btn-success mt-3 mx-2 col-md-4" ><i className="fa fa-save"></i> Update Information</button>
                                                    :
                                                    <button type={"submit"} className="btn btn-sm btn-success mt-3 mx-2 col-md-4" ><i className="fa fa-save"></i> Update Information</button>
                                            }
                                            {

                                            }



                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1">&nbsp;</div>
                </div>
            </div>
        </>
    )
}

export default ConfirmDetail;