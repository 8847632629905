import React, {useEffect, useState} from "react";
// import {useHistory} from "react-router";
import Axios from "axios";
import {Link, Redirect, useHistory} from "react-router-dom";
import {ReactSession} from "react-client-session";
import {IsValidLogin} from "../isLoggedIn";
import fs from 'fs';
import Sidebar from "../../sidebar/Sidebar";
// import {withRouter} from "react-router-dom";

const StudentDashboard=props=>{
    ReactSession.setStoreType("localStorage");
    const history=useHistory();

    const [appliedStreamCollege,setAppliedStreamCollege]=useState([{
    }]);

    const [applicationDetails,setApplicationDetails]=useState([{}]);
    const [stream,setStream]=useState({
        stream_id:0,stream_name:"",stream_description:""
    })
    const [convFeePaid,setConvFeePaid]=useState(false);
    const [regFeePaid,setRegFeePaid]=useState(false);
    const [convFeeStatus,setConvFeeStatus]=useState(false);
    const getStreamDetail=async ()=>{
        const stream_id=1;
        const streamDetail=await Axios.get(`${process.env.REACT_APP_URI}stream/${stream_id}`);
        console.log(stream)
    }

    const getCollegeDetails=async ()=>{
        const college_id=1;
        const collegeDetail=await Axios.get(`${process.env.REACT_APP_URI}college/${college_id}`);
        console.log(stream)
    }
    const [showStatus,setShowStatus]=useState(true);
    const getAppliedStreamList=async ()=>{
        {/* get all information about the applied streams and colleges*/}
        // const ApplicationID=await Axios.get(`${process.env.REACT_APP_URI}student/stream/apply/${ReactSession.get("student_id")}`)
        // console.log(getStreamsApplied);
        // setAppliedStreamCollege(getStreamsApplied.data.streamApplied);
        const getStreamsApplied=await Axios.get(`${process.env.REACT_APP_URI}student/stream/apply/${ReactSession.get("student_id")}`)
        console.log(getStreamsApplied);
        setAppliedStreamCollege(getStreamsApplied.data.streamApplied);
        setShowStatus(false);
        // ReactSession.set("college_id",)
    }

    const [appliedStream,setAppliedStream]=useState([{}]);
    const getAppliedStreamList1=async ()=>{

        const getStreamsApplied=await Axios.get(`${process.env.REACT_APP_URI}student/stream/apply/admstatus/${ReactSession.get("student_id")}`)
        console.log(getStreamsApplied);
        setAppliedStreamCollege(getStreamsApplied.data.streamApplied);
        setShowStatus(false);

    }
    //Logic to check CovFeePaid or Not
    // const
    //Logic to check RegistrationFee Paid of not

    const gotoConvPayment=()=>{
        console.log('Need to Pay Fee');
    }

    const [admissionStatus,setAdmissionStatus]=useState(false);
    const AdmissionStatus=async (streamid)=>{
        const admission=await Axios.get(`${process.env.REACT_APP_URI}student/admission/status/${ReactSession.get("student_id")}/${streamid}`).then((res)=>{
            console.log(res)
        })
    }

    const gotoRegPayment=()=>{
        if(convFeeStatus)
        {
            console.log("Go to Pay Registration Fee")
        }
        else{
            console.log('Need to Pay Conv. Fee');
        }
        //
    }

    const getConvFeeStatus=async ()=>{
        // console.log("Hit me");
        // const collegeid=1;
        const collegeid=1;
        // console.log("clgid ->",clgid)
        const uri=`${process.env.REACT_APP_URI}fee/conv/status/${ReactSession.get("student_id")}/${collegeid}`;
        const convFeeStatus=await Axios.get(uri);
        // console.log(await convFeeStatus);
        setConvFeeStatus(convFeeStatus.data.isConvPaid);
        // return convFeeStatus.data.isConvPaid;
    }

    const getAdmFeeStatus=async ()=>{
        const uri=`${process.env.REACT_APP_URI}student/stream/apply/admstatus/${ReactSession.get("student_id")}`;
        const admFeeStatus=await Axios.get(uri);
        // console.log(await convFeeStatus);
        if(admFeeStatus.length>0)
        {
            // alert('message')
            setAdmissionStatus(true)
        }else {
            setAdmissionStatus(false)
        }
        // setAdmissionStatus(convFeeStatus.data.isConvPaid);
    }

    const getRegFeeStatus=async (clgid,strid)=>{
        // console.log("Hit me");
        // const collegeid=1;
        const collegeid=clgid;
        const streamid=strid;
        // console.log("clgid ->",clgid)
        const uri=`${process.env.REACT_APP_URI}fee/conv/status/${ReactSession.get("student_id")}/${collegeid}/${streamid}`;
        const convFeeStatus=await Axios.get(uri);
        // console.log(await convFeeStatus);
        setConvFeeStatus(convFeeStatus.data.isConvPaid);
    }

    const getConvFeeAmount=async ()=>{
        // console.log("Hit me");
        const uri=`${process.env.REACT_APP_URI}fee/conv/amount/${ReactSession.get("student_id")}`;
        const convFeeAmount=await Axios.get(uri);
        console.log(await convFeeAmount);
        // setConvFeeAmount(convFeeAmount.data);
    }

    useEffect(()=>{
        if(!IsValidLogin()){
            // console.log("entered");
            localStorage.clear();
            history.push("/");
        }
        // alert(isProfileCompleted);

        getAppliedStreamList();
        getConvFeeStatus();
        getConvFeeDetails();
        getProfileData();
        // getAdmissionStatus();
        {/*Check whether the conv. fee paid or not*/}
        getAdmFeeStatus();
        getAcademicDetails();
        isRegistrationEnabled();
        selectRegistrationFee();
        Registered();
        getPDF();
        {/*Check where the prospectus (registration)node fee is paid or not*/}
    },[]);
    const [convFeeDetails,setConvFeeDetails]=useState([]);
    const getConvFeeDetails=async ()=>{
        const uri=`${process.env.REACT_APP_URI}fee/conv/details/${ReactSession.get("student_id")}`;
        // alert(uri);
        const college_ids=await Axios.get(uri,{
            payment_status:'success',
        });
        setConvFeeDetails(await college_ids.data);
        // console.log("college->",college_ids.data);
    }

    const [student,setStudent]=useState({
        student_name:"",student_fathername:"",student_dob:"",student_gender:"",
        student_mobile:"",student_email:"",student_batch:"",
    });

    let eleName,value;
    const handleInputs=(e)=>{
        eleName=e.target.name;
        value=e.target.value;
        setStudent({...student,[eleName]:value});
    }
    const logout=()=>{
        localStorage.clear();
        history.push("/");
    }
    const [isProfileCompleted,setIsProfileCompleted]=useState(false);
    const [student_category,setStudentCategory]=useState(0)
    const getProfileData=async ()=>{
        try {
            const student=await Axios.get(`${process.env.REACT_APP_URI}student/byid/${ReactSession.get("student_id")}`);
            ReactSession.set("student_gender",student.data.student_gender)
            // console.log(`${process.env.REACT_APP_URI}student/detail/all/${ReactSession.get("student_id")}`)
            const getProfile = await Axios.get(`${process.env.REACT_APP_URI}student/detail/all/${ReactSession.get("student_id")}`)
                .then((res) => {
                    if (res.data.length > 0) {
                        console.log(res.data);
                        setIsProfileCompleted(true);
                        ReactSession.set("student_category",res.data[0].student_caste)
                        setStudentCategory(res.data[0].student_caste)
                    }
                }).catch(err => {
                    console.log(err.message)
                })
        }catch (err){
            console.log(err);
        }
        // console.log(isProfileCompleted);

    }


    const getAppId=async ()=>{
        // console.log(college_id);
        alert('Application ID is going to be generated. Please Refresh the page.');
        history.push('/student/dashboard');
        var currentYear =  (new Date()).getFullYear();
        var twoLastDigits = currentYear%100;
        var formatedTwoLastDigits = "";

        if (twoLastDigits <10 ) {
            formatedTwoLastDigits = "0" + twoLastDigits;
        } else {
            formatedTwoLastDigits = "" + twoLastDigits;
        }
        var formatedStudentId = "";
        if(ReactSession.get("student_id")<10){
            formatedStudentId="000"+ReactSession.get("student_id")
        }
        else if(ReactSession.get("student_id")<100){
            formatedStudentId="00"+ReactSession.get("student_id")
        }else if(ReactSession.get("student_id")<1000){
            formatedStudentId="0"+ReactSession.get("student_id")
        }
        else{
            formatedStudentId=ReactSession.get("student_id");
        }
        // if(this.props.match.strid!=undefined)
        // {
        var appid="test";
        // const [appID,setAppID]=useState('');
        const college=await Axios.get(`${process.env.REACT_APP_URI}college/${ReactSession.get("college_id")}`)
        ReactSession.set("college_code",college.data.college_code);
        // if(college.college_code!==undefined){
        var appid= formatedTwoLastDigits.toString()+ReactSession.get("college_code")+ReactSession.get("stream_id")+formatedStudentId.toString();
        //     setAppID(appid);
        // }
        //regFee[0].college_code;//+formatedTwoLastDigits.toString()+formatedStudentId.toString()+props.match.college_id.toString()+props.match.strid.toString();
        // }
        // debugger
        ReactSession.set("Application_ID",appid)
        await Axios.put(`${process.env.REACT_APP_URI}student/stream/app/${ReactSession.get("student_id")}/${ReactSession.get("stream_id")}/${ReactSession.get("college_id")}`,{
            application_no:appid,
        }).then((res)=>{
            console.log("Application ID Updated Successfully")
        })
        // alert("Application Number: ",appid);
    }

    const updateProfileData=async ()=>{
        const getProfile=await Axios.get(`${process.env.REACT_APP_URI}student/detail/id/${ReactSession.get("student_id")}`);
        // update data will effect
    }

    // const getAdmissionStatus=()=>{
    //
    // }
    const printRedirection=(college_id,stream_id)=>{
        history.push(`/student/print/application/${college_id}/${stream_id}`);
    }

    const [acadDetails,setAcadDetails]=useState([{}])
    const [completeSubjectsDetails,setCompleteSubjectsDetails]=useState([{}])
    const getAcademicDetails=async ()=>{
        const acadDetails1=await Axios.get(`${process.env.REACT_APP_URI}student/academic/marks/detail/${ReactSession.get("student_id")}`)
            .then((res)=>{
                console.log("AcadDetails",res.data.subjectDetails)
                ReactSession.set("Previous_school",res.data.subjectDetails[0].school_name)
                ReactSession.set("Passing_Year",res.data.subjectDetails[0].pass_year)
                ReactSession.set("board_name",res.data.subjectDetails[0].board_name)
                setAcadDetails(res.data.subjectDetails);
                setCompleteSubjectsDetails(res.data.completeSubjectDetails)
            }).catch(err=>{
                console.log(err.message)
            })


    }
    const [appr,setAppr]=useState(false)
    const getApproval=async (stream_id)=>{
        //Approval works only for the ARTS Stream
        console.log("Appr StudentID",ReactSession.get("student_id"))
        const aprroval=await Axios.get(`${process.env.REACT_APP_URI}student/approved/applicationid/${ReactSession.get("student_id")}`)
            .then((res)=>{
            console.log("Appr",stream_id,res.data)
                setAppr(res.data.isAllowed)
        })
    }

    const postApplyNewStream=()=>{
        // alert(isProfileCompleted)
        console.log(isProfileCompleted);
        if (isProfileCompleted){
            history.push('/student/previous/academic');
            // alert("Please Complete your profile first");
            // history.push(`/student/profile/update/${ReactSession.get("student_id")}`);
            // return;
        }
        else{
            // history.push('/student/applied/stream');
            alert("Please Complete your profile first");
            history.push(`/student/profile/update/${ReactSession.get("student_id")}`);
        }
    }
    const [fee,setFee]=useState(0);
    const changeRegistration=(e)=>{
        const elements=e.target.value.split('_')
        console.log(elements[1]);
        setFee(e.target.value);
        ReactSession.set('amount',elements[0])
        ReactSession.set('rfeemaster_id',elements[1])
    }
    const postRegistrationFee=()=>{
        //var fee=document.getElementById('feeDetails').value
        alert('You need to pay '+ReactSession.get('amount').toString())
        // ReactSession.set('amount',ReactSession.set('amount')
        history.push(`/student/regp/fee/${ReactSession.get("student_id")}/2`);
    }

    const [isRegistered,setIsRegistered]=useState(false);
    const Registered=async ()=>{
        //logic to check the logged in user is admitted or not
        const admitted=await Axios.get(`${process.env.REACT_APP_URI}student/approved/registration/id/${ReactSession.get("student_id")}`)
            .then((res)=>{
                console.log("Registered",res.data)
                setIsRegistered(res.data.isAllowed)
            });
    }

    const printRegistrationForm=()=>{
        //var fee=document.getElementById('feeDetails').value
        // alert('You need to pay '+ReactSession.get('amount').toString())
        // ReactSession.set('amount',ReactSession.set('amount')
        history.push(`/student/regp/fee/${ReactSession.get("student_id")}/2`);
    }
    const [roll,setRoll]=useState({roll:'',stream:''});
    const [haveRoll,setHaveRoll]=useState(false);
    const [uri,setURI]=useState(null)
    const getPDF=async ()=>{
        const pdf=await Axios.get(`${process.env.REACT_APP_URI}student/check/roll/updated/${ReactSession.get("student_id")}`)
            .then((res)=>{
                console.log("PDF Data",res)
                if(res.data.noroll){
                    // alert('Roll number is not updated yet.')
                    setHaveRoll(false)
                }else{
                    setHaveRoll(true)
                    ReactSession.set('roll_no',res.data[0].class_rollno);
                    // var file=`/reg/${res.data[0].stream_id}/${res.data[0].class_rollno}.pdf`;
                    // if(file.ex)
                    // {
                    //     alert('file Exists')
                    // }
                    setURI(`${process.env.REACT_APP_URI}reg/${res.data[0].stream_id}/${res.data[0].class_rollno}.pdf`)
                    // console.log("URI",`${process.env.REACT_APP_URI}reg/${res.data[0].stream_id}/${res.data[0].class_rollno}.pdf`)
                }
            });
    }


    const [isAdmitted,setIsAdmitted]=useState(false);
    const isRegistrationEnabled=async ()=>{
        //logic to check the logged in user is admitted or not
        const admitted=await Axios.get(`${process.env.REACT_APP_URI}student/approved/applicationid/id/${ReactSession.get("student_id")}`)
        .then((res)=>{
            console.log("Admitted",res.data)
            setIsAdmitted(res.data.isAllowed)
        });
    }
    const [regFeeList,setRegFeeList]=useState([]);
    const selectRegistrationFee=async ()=>{
        const list=await Axios.get(`${process.env.REACT_APP_URI}student/registration/fee/list`)
            .then((res)=>{
                console.log("List ",res.data)
                setRegFeeList(res.data)
            });
    }

    const [fd,setFD]=useState(false)
    const isFirstDiv=(marks)=>{
        if(parseInt(marks)>59){
            // setFD(true)
            return true
        }
        else{
            // setFD(false)
            return false;
        }
    }
    const handleAdmission=async (stream_id)=>{
        // alert(stream_id)
        if(applicationNo!==""){
            //do nothing
        }else{
            alert('Please provide the Application Number to proceed further');
        }
        //
        const validApplication=await Axios.get(`${process.env.REACT_APP_URI}student/verify/application/number/${applicationNo}/${ReactSession.get("student_id")}`)
            .then((res)=>{
                console.log(res)
                // console.log("Application Number and Details for the verification",res.data[0].student_id===parseInt(ReactSession.get("student_id")))
            if(res.data.length>0){
                if((res.data[0].student_id===parseInt(ReactSession.get("student_id"))) && (res.data[0].application_no===applicationNo)){
                    ReactSession.set('isAppidFilled','Filled');
                    history.push(`/student/confirm/adm/detail/2/${stream_id}/${ReactSession.get("student_id")}`)
                }
                else{
                    alert('You are not Eligible for the admission because data could not retrieve.');
                    history.push('/')
                }
            }
            else{
                alert('Either You are not Eligible for the admission or you have entered wrong application number.')
                history.push('/')
            }
        })

    }
    const [applicationNo,setApplicationNo]=useState('')
    const handleAdmissionNo=(e)=>{
        setApplicationNo(e.target.value)
        console.log(e.target.value);
    }

    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    {/*<div className="col-md-3">*/}
                    {/*    /!*<div className="card border-secondary mt-3 text-justify">*!/*/}
                    {/*    /!*    /!*<div className="card-header"><h4 className="card-title"> Menu bars</h4></div>*!/*!/*/}
                    {/*    /!*    /!*<div className="card-body">*!/*!/*/}
                    {/*    /!*    /!*    <a className="btn btn-sm btn-danger" onClick={logout}>Logout</a>*!/*!/*/}
                    {/*    /!*    /!*</div>*!/*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*    <Sidebar/>*/}
                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="card border-secondary mt-3 text-justify">
                            <div className="card-header">
                                <h5 className="card-title"> Welcome : {ReactSession.get("student_name")} </h5>
                                <h4 className="card-title"> Applied Stream</h4></div>
                            <div className="card-body">
                                <div className="alert alert-dismissible alert-primary">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert"></button>
                                    If you have applied for the stream and streams are not shown up here <strong>Kindly refresh the page.</strong>
                                </div>
                                <div className="container mt-2 mb-2">

                                        {/*<div className="row border">*/}
                                        {/*    {*/}
                                        {/*        convFeeStatus?*/}
                                        {/*            <label className="btn btn-sm btn-block btn-success">Conv. Fee Paid</label>:*/}
                                        {/*            <label className="btn btn-sm btn-block btn-danger"*/}
                                        {/*                   onClick={getConvFeeStatus}>Pay Conv. Fee</label>*/}
                                        {/*    }*/}
                                        {/*</div>*/}

                                    <div className="row border fw-bold">
                                        <div className="col-md-4 border">College Name</div>
                                        <div className="col-md-3 border">Stream Name</div>
                                        <div className="col-md-5">Action</div>
                                        {/*<div className="col-md-3">Reg Fee Status</div>*/}
                                    </div>
                                        {/*fetch all applied stream list
                                        college name->stream name->payment status*/}

                                    {
                                        appliedStreamCollege<1?

                                        <div className="row border" >
                                            No record found.
                                        </div>

                                            :
                                        appliedStreamCollege.map( item=> {

                                              return (<>
                                                      <div className="row border p-1" >
                                                          <div className="col-md-4 border">{item.college_name}</div>
                                                          <div className="col-md-3 border">{item.stream_name}</div>
                                                          <div className="col-md-5 border p-1">
                                                              {/*<div className="col-md-5 border p-1 text-danger">*/}
                                                              {/*Admission Closed*/}
                                                              {/*Whole code must be enable to enable payment options*/}
                                                              {ReactSession.set("stream_id",item.stream_id)}

                                                              {
                                                                  item.application_no!==null?
                                                                      <Link to={`/student/print/application/${item.college_id}/${item.stream_id}`}  className="btn btn-primary btn-sm"  > <i className="fa fa-print"></i> Application
                                                                          {
                                                                              ReactSession.set("Application_ID",item.application_no)
                                                                          }
                                                                      </Link>
                                                                      :
                                                                      <button  className="btn btn-success btn-sm" onClick={getAppId} > <i className="fa fa-print"></i> Get Application ID </button>
                                                              }

                                                              {

                                                                  (item.stream_id===4)?
                                                                      <Link to={`/student/confirm/adm/detail/${item.college_id}/${item.stream_id}/${ReactSession.get("student_id")}`
                                                                      }  className="btn btn-warning btn-sm mx-1">
                                                                         Pay Admission Fee
                                                                      </Link>
                                                                      :
                                                                      (acadDetails.map(item1=>{

                                                                          (getApproval(item.stream_id))
                                                                          console.log('Appr',appr)
                                                                          if((item1.average_marks>99.99)|| appr){
                                                                              // ""
                                                                              // debugger
                                                                                  return (<>
                                                                                      <input onChange={handleAdmissionNo} type={'text'} className={'form-control mb-1 mt-1'} name={'application_no'} placeholder={'Application No.'} required />
                                                                                      <button onClick={()=>handleAdmission(item.stream_id)} className={'btn btn-warning btn-sm'}> <i className="fa fa-credit-card"></i>
                                                                                          Pay Admission Fee
                                                                                      </button>
                                                                                  </>)
                                                                          }
                                                                          else{
                                                                              return ""
                                                                          }
                                                                      }))
                                                              }

                                                              {/*{*/}
                                                              {/*    //if Admission fee is already paid then show the button otherwise*/}
                                                              {/*    //show nil*/}
                                                              {/*    (AdmissionStatus(item.stream_id))?*/}
                                                              {/*        ///print/admission/form/:stid/:clgid/:strid*/}
                                                              {/*        <Link to={*/}
                                                              {/*            `/print/admission/form/${ReactSession.get("student_id")}/${item.college_id}/${item.stream_id}`*/}
                                                              {/*        }  className="btn btn-success btn-sm mx-1" label={"Admission Form"}*/}
                                                              {/*        > <i className="fa fa-print"></i>*/}

                                                              {/*        </Link>*/}
                                                              {/*        :""*/}

                                                              {/*}*/}

                                                              {
                                                                  ReactSession.set("college_id",item.college_id)
                                                              }
                                                          </div>
                                                      </div>
                                                  {/*<label className="btn btn-sm btn-success"></label>*/}
                                              </>)
                                            }
                                        )
                                    }

                                </div>

                            </div>


                            <div className="card-header"><h4 className="card-title"> Student Dashboard</h4></div>
                            <div className="card-body">
                                <div className="container form-floating">
                                    {/*<Link to="/student/applied/stream" className="btn btn-sm btn-primary mx-3" >Apply for New Stream</Link>*/}
                                    <button className="btn btn-sm btn-primary mx-3 mt-1" onClick={postApplyNewStream}>Apply for New Stream</button>
                                    {isProfileCompleted?
                                        <Link to="/student/view/detail" className="btn btn-sm btn-success mx-3 mt-1 ">Your Complete Profile</Link>
                                        :
                                        <Link to="/student/apply/stream" className="btn btn-sm btn-danger mx-3 mt-1">Edit Profile</Link>
                                        // <Redirect to="/student/convenience-fee" >Test</Redirect>
                                    }

                                    {
                                        isAdmitted?
                                            <>
                                                <Link to="/update/student/rollno" name="updateroll_no" className="btn btn-sm btn-warning btn-block"
                                                >Update your Roll Number
                                                </Link>
                                                {
                                                    haveRoll?
                                                        <a href={uri} name="view_regchart" className="btn btn-sm btn-primary btn-block mx-3"
                                                        >Registration Details
                                                        </a>
                                                        :
                                                        ''
                                                }


                                            </>
                                            :
                                            ''
                                    }
                                    {/*<Link to="/student/add/caste" className="btn btn-sm btn-primary mx-3 mt-1">Update Caste information</Link>*/}
                                    <Link to={'/student/upload/proof'} className="btn btn-sm btn-primary mx-3 mt-1" >Upload your Photo and Signature</Link>

                                    {/*<Link to="/student/applied/stream" className="btn btn-sm btn-primary mx-3" >Apply for New Stream</Link>*/}
                                    {
                                        isAdmitted?
                                            <>
                                                {/*Display All Fee Selecting Details from the DB*/}
                                                <select name='feeDetails' onChange={changeRegistration} value={fee?fee:''} required={true}>
                                                    <option value="">--Select Registration Type-- </option>
                                                    {
                                                        regFeeList.map(item=>{
                                                            return <option value={`${item.rfeemaster_amount}_${item.rfeemaster_name}`}>{item.rfeemaster_name} (Rs.{item.rfeemaster_amount}) </option>
                                                        })
                                                    }
                                                </select>
                                                <button className="btn btn-sm btn-warning mx-3 mt-1" onClick={postRegistrationFee}>Pay Registration Fee</button>
                                                {
                                                    // isRegistered?
                                                    //     <Link to="/student/print/registration/detail" name="registration" className="btn btn-sm btn-success btn-block"
                                                    //         // onChange={handleInputs} value={selectDefault}
                                                    //     >Print Registration Form
                                                    //     </Link>:
                                                    //     ''
                                                }

                                            </>

                                            :
                                            <button  className="btn btn-sm btn-danger mx-3 mt-1" onClick={()=>alert('Admission is not Done. Please contact to Admission In-charge')}>Not Admitted</button>


                                    }

                                </div>

                            </div>
                        </div>
                    </div>



                    <div className="col-md-3">&nbsp;</div>
                </div>
            </div>
        </>
    )
}

export default StudentDashboard;