import React, {useEffect, useRef, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import './Sidebar.css'
import {Link, useHistory} from "react-router-dom";
import {ReactSession} from "react-client-session";
import Axios from "axios";
import {IsValidLogin} from "../Student/isLoggedIn";

const Sidebar=()=>{
    const isMounted = useRef(false)
    useEffect(()=>{
        if(!IsValidLogin()){
            // console.log("entered");
            localStorage.clear();
            history.push("/");
        }
        isMounted.current=true;
        getProfileData();
        isRegistrationEnabled()
        getPDF();
        Registered();
        return ()=>{
            isMounted.current=false;
        }
        {/*Check where the prospectus (registration)node fee is paid or not*/}
    },[]);
    const history=useHistory();
    const logout=()=>{
        localStorage.clear();
        history.push("/");
    }
    ReactSession.setStoreType("localStorage");
    const [loginStatus,setLoginStatus]=useState(ReactSession.get("login_status"))
    const [isRegistered,setIsRegistered]=useState(false);
    const Registered=async ()=>{
        //logic to check the logged in user is admitted or not
        const admitted=await Axios.get(`${process.env.REACT_APP_URI}student/approved/registration/id/${ReactSession.get("student_id")}`)
            .then((res)=>{
                console.log("Registered",res.data)
                setIsRegistered(res.data.isAllowed)
            });
    }
    const [isProfileCompleted,setIsProfileCompleted]=useState(false);
    const [student_category,setStudentCategory]=useState(0)
    const getProfileData=async ()=>{
        try {
            const student=await Axios.get(`${process.env.REACT_APP_URI}student/byid/${ReactSession.get("student_id")}`);
            ReactSession.set("student_gender",student.data.student_gender)
            // console.log(`${process.env.REACT_APP_URI}student/detail/all/${ReactSession.get("student_id")}`)
            const getProfile = await Axios.get(`${process.env.REACT_APP_URI}student/detail/all/${ReactSession.get("student_id")}`)
                .then((res) => {
                    if (res.data.length > 0) {
                        console.log(res.data);
                        setIsProfileCompleted(true);
                        ReactSession.set("student_category",res.data[0].student_caste)
                        setStudentCategory(res.data[0].student_caste)
                    }
                }).catch(err => {
                    console.log(err.message)
                })
        }catch (err){
            console.log(err);
        }
        // console.log(isProfileCompleted);

    }

    const postApplyNewStream=()=>{
        // alert(isProfileCompleted)
        console.log(isProfileCompleted);
        if (isProfileCompleted){
            history.push('/student/previous/academic');
            // alert("Please Complete your profile first");
            // history.push(`/student/profile/update/${ReactSession.get("student_id")}`);
            // return;
        }
        else{
            // history.push('/student/applied/stream');
            alert("Please Complete your profile first");
            history.push(`/student/profile/update/${ReactSession.get("student_id")}`);
        }
    }

    const [isAdmitted,setIsAdmitted]=useState(false);
    const isRegistrationEnabled=async ()=>{
        //logic to check the logged in user is admitted or not
        const admitted=await Axios.get(`${process.env.REACT_APP_URI}student/approved/applicationid/id/${ReactSession.get("student_id")}`)
            .then((res)=>{
                console.log("Admitted",res.data)
                if(res.data.length>0) {
                    setIsAdmitted(res.data.isAllowed)
                }
            });
    }

    const [roll,setRoll]=useState({roll:'',stream:''});
    const [haveRoll,setHaveRoll]=useState(false);
    const [uri,setURI]=useState(null)
    const getPDF=async ()=>{
        const pdf=await Axios.get(`${process.env.REACT_APP_URI}student/check/roll/updated/${ReactSession.get("student_id")}`)
            .then((res)=>{
                console.log("PDF Data",res)
                if(res.data.noroll){
                    // alert('Roll number is not updated yet.')
                    setHaveRoll(false)
                }else{
                    setHaveRoll(true)
                    ReactSession.set('roll_no',res.data[0].class_rollno);
                    // var file=`/reg/${res.data[0].stream_id}/${res.data[0].class_rollno}.pdf`;
                    // if(file.ex)
                    // {
                    //     alert('file Exists')
                    // }
                    setURI(`${process.env.REACT_APP_URI}reg/${res.data[0].stream_id}/${res.data[0].class_rollno}.pdf`)
                    // console.log("URI",`${process.env.REACT_APP_URI}reg/${res.data[0].stream_id}/${res.data[0].class_rollno}.pdf`)
                }
            });
    }

    return(
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 min-vh-100 bg-light">
                        <nav>
                            <div className="col-12 text-center">
                                <img src='img/logo.jpg'/>
                            </div>
                            {
                                loginStatus?
                            <ul className='mt-5'>
                                <li className='nav-item'><Link to="/student/dashboard" className="nav-link px-2">
                                    <i className="fa fa-tachometer" aria-hidden="true"></i>
                                    <span className='ms-1 d-none d-sm-inline'>Dashboard</span>
                                </Link></li>
                                {/*<li><a href="" className="nav-link px-2" onClick={postApplyNewStream}>*/}
                                {/*    <i className="fa fa-check-circle-o" aria-hidden="true"></i>*/}
                                {/*    <span className='ms-1 d-none d-sm-inline' >Apply for Stream</span>*/}
                                {/*</a></li>*/}
                                <li>
                                    {isProfileCompleted?
                                        <Link to="/student/view/detail" className="nav-link px-2">
                                            <i className="fa fa-users" aria-hidden="true"></i> Your Complete Profile</Link>
                                        :
                                        <Link to="/student/apply/stream" className="nav-link px-2 text-danger"><i
                                            className="fa fa-pencil" aria-hidden="true"></i> Edit Profile</Link>
                                        // <Redirect to="/student/convenience-fee" >Test</Redirect>
                                    }

                               </li>
                                <li>
                                    <Link to="/update/student/rollno" name="updateroll_no" className="nav-link px-2"
                                    ><i className="fa fa-refresh" aria-hidden="true"></i> Update your Roll Number
                                    </Link>
                                </li>

                                <li>
                                    {
                                    isAdmitted?
                                        <>

                                            {
                                                haveRoll?
                                                    <a href={uri} name="view_regchart" className="nav-link px-2"
                                                    ><i className="fa fa-registered" aria-hidden="true"></i> Registration Details
                                                    </a>
                                                    :
                                                    ''
                                            }


                                        </>
                                        :
                                        ''
                                }
                                </li>

                                <li>
                                    <Link to={'/student/upload/proof'} className="nav-link px-2" ><i className="fa fa-cloud-upload" aria-hidden="true"></i> Upload Photo and Sign</Link>
                                </li>
                                {
                                    isAdmitted ?
                                            isRegistered?
                                                <li>
                                                    <Link to="/student/print/registration/detail" name="registration"
                                                          className="nav-link px-2"><i
                                                        className="fa fa-print"></i> Print Registration Form
                                                    </Link>
                                                </li>
                                               :''
                                        :''
                                }

                                <li><a href="" className="nav-link px-2">
                                    <i className="fa fa-sign-out text-danger"></i>
                                    <span className='ms-1 d-none d-sm-inline text-danger' onClick={logout}>Logout</span>
                                </a></li>



                            </ul>:
                                    ''
                            }

                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar