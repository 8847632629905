import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import {ReactSession} from "react-client-session";
import {Container,Row,Col,Form,FormLabel,FormControl, Button} from "react-bootstrap";
import {IsValidLogin} from "../../isLoggedIn";
import {useHistory, useLocation} from "react-router-dom";
import {PaymentInitModal}   from "pg-test-project";
import SabpaisaSdk from "./SabPaisaComp";
import Encryptor from 'simple-encryptor'
import SabpaisaPaymentGateway from "./SabPaisaPaymentGateway";
import {process} from "uniqid";
// import {useSearchParams} from 'react-router-dom'
// const dotenv=require('dotenv');
// dotenv.config();



const MakePaymentReg=(props)=>{

    const location = useLocation()
    const GetPaymentDetails=()=>{

        const queryParameters = new URLSearchParams(location.search);
        console.log("Payment accepted for "+queryParameters.get("payerName"))
    }
    //const clientDetails = props?.clientData
    const [isOpen, setIsOpen] = useState(false);
    // const [clientCode, setClientCode] = useState("PBNMI");
    // const [transUserName, setTransUserName] = useState("bhabesh.jha_9775");
    // const [transUserPassword, setTransUserPassword] = useState("PBNMI_SP9775");
    // // const [authkey, setAuthkey] = useState("aPar7nfCzfbWCR3n");
    // const [authkey, setAuthkey] = useState("YN2v8qQcU3rGfA1y");
    // // const [authiv, setAuthiv] = useState("ARaDxrmvMfRVoEJQ");
    // const [authiv, setAuthiv] = useState("YN2v8qQcU3rGfA1y");


    const [callbackUrl, setCallbackUrl] = useState("https://jac.pbnjmicollegedeoghar.com/response");
    const [payerName, setPayerName] = useState(ReactSession.get("student_name"));
    const [payerEmail, setPayerEmail] = useState(ReactSession.get("student_email"));
    const [payerMobile, setPayerMobile] = useState(ReactSession.get("student_mobile"));
    const [clientTxnId, setclientTxnId] = useState(Math.random().toString());
    // const [clientTxnId, setclientTxnId] = useState("reg_" + ReactSession.get("txnid"));
    const [payerAddress, setPayerAddress] = useState("");
    const [amountType, setamountType] = useState("INR");
    const [udf1, setudf1] = useState("");
    const [udf2, setudf2] = useState("");
    const [udf3, setudf3] = useState("");
    const [udf4, setudf4] = useState("");
    const [udf5, setudf5] = useState("");
    const [udf6, setudf6] = useState("");
    const [udf7, setudf7] = useState("");
    const [udf8, setudf8] = useState("");
    const [udf9, setudf9] = useState("");
    const [udf10, setudf10] = useState("");
    const [udf11, setudf11] = useState("");
    const [udf12, setudf12] = useState(""); // client id
    const [udf13, setudf13] = useState(""); // plan id
    const [udf14, setudf14] = useState(""); // plan name
    const [udf15, setudf15] = useState(""); // application id (product id)
    const [udf16, setudf16] = useState(""); // // client subscribe plan detail id(refer to DB)
    const [udf17, setudf17] = useState(""); // payment from the COB portal
    const [udf18, setudf18] = useState("");
    const [udf19, setudf19] = useState("");
    const [udf20, setudf20] = useState("");
    const [channelId, setchannelId] = useState("");
    const [programId, setprogramId] = useState("");
    const [mcc, setmcc] = useState("");
   const [sabpaisaUrl, setSabpaisaUrl]=useState('https://securepay.sabpaisa.in/SabPaisa/sabPaisaInit?v=1')
    const history=useHistory();
    ReactSession.setStoreType("localStorage");
    const [txnid, setTxnId] = useState("reg_" + ReactSession.get("txnid"));
    //txnID will be get from the props
    const [amount, setAmount] = useState(ReactSession.get("amount"));
    // const [firstname, setFirstName] = useState(ReactSession.get("student_name"));
    // const [email, setEmail] = useState(ReactSession.get("student_email"));
    // const [phone, setPhone] = useState(ReactSession.get("student_mobile"));

    const [hash, setHash] = useState('');

    useEffect(async () => {
        GetPaymentDetails();
        if(!IsValidLogin()){
            // console.log("entered");
            localStorage.clear();
            history.push("/");
        }
        if(ReactSession.get("amount")!=="" || ReactSession.get("amount")!==null || ReactSession.get("amount")!==undefined) {
            setAmount(ReactSession.get("amount"))
        }
        console.log(payerName);
        Decrypt();
        // if(responseCode===null){
        const searchParams=new URLSearchParams(window.location.search);
        console.log('PArameters'+searchParams.has('status'));
        if(searchParams.has('status')) {
            const status1 = searchParams.get('status');
            setAmount(searchParams.get('paidAmount'))
            setTxnId(searchParams.get('sabpaisaTxnId'))
            // console.log(searchParams.get('status'));
            setStatus(status1);
            var std_email = searchParams.get('payerEmail')
            //set the status of student to success
            if (searchParams.get('status')==="SUCCESS") {
                // const uri=
                // console.log('success request');
                const pay_status=await Axios.put(`https://adm.pbnjmicollegedeoghar.com/fee/reg/details/${ReactSession.get('txnid')}`,{payment_status:'success'})
                    .then(res=>{
                        history.push('/payment/payu/response/SUCCESS');
                    })
            }else{
                const uri=`https://adm.pbnjmicollegedeoghar.com/fee/reg/details/${ReactSession.get('txnid')}`
                console.log('failed request');
                const pay_status=await Axios.put(uri,{payment_status:'failed'})
                    .then(res=>{
                        history.push('/payment/payu/response/FAILED');
                    })
                //
            }

        }
        // }
       //Code for Sab Paisa
    }, [txnid, amount, payerName, payerMobile, payerEmail]);
    const search=useLocation().search;
    const [status,setStatus]=useState("Pending");
    const [student,setStudent]=useState({});
    const[payResponse,setPayResponse]=useState('');
    const Decrypt=async ()=>{
        // Encryptor enc=new Encryptor();
        const searchParams=new URLSearchParams(search)
        const responseCode=searchParams.get('encResponse');
        setPayResponse(responseCode);
        // console.log(Encryptor.decrypt(responseCode));
    }



    const [confirm1,setConfirm1]=useState(false);
    const Confirmation1=(e)=>{
        alert("Proceed to pay")
        setConfirm1(true);

    }

    const handleSubmit = (e) => {
        // const paramsData={
        //   userName:values.username
        //   udf1:"",
        // }
        setIsOpen(true);
        e.preventDefault()
        // console.log(payerName);
        // console.log(payerEmail);
        const btn = document.getElementById('submitSabPaisaBtn');
        // console.log('btn pushed'+btn);
        // btn.click();
        // setData()

    }
    // console.log(payerName);
    // const startPayment=()=> {
    //     alert('Will proceed for the payment');
    //     //Create new payment details. if it is successful then get paymentdetail_id to generate the txnID
    //     //Store all details into the localStorage to make n easy fetch request
    //     //Payment Code placed here
    // }

    return(
        <div className="container text-lg-center mt-4">
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Applied College Name</th>
                    <th>Applied Stream Name</th>
                    {
                        status?
                        <th>Payment Status</th>
                        :
                        ""
                    }
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{ReactSession.get('student_name')?ReactSession.get('student_name'):"NA"}</td>
                    <td>{status?"PBNJMI College":"NA"}</td>
                    <td>{ReactSession.get('streamid')?ReactSession.get('streamid'):"NA"}</td>
                    <td>{status?status:"NA"}</td>
                </tr>
                </tbody>
            </table>

            {/*<Form action={baseURL} method="post" onSubmit={calcHash}>*/}
            {/*    <Form.Control type="hidden" name="key" value={key} onChange={calcHash}></Form.Control>*/}
            {/*    <FormControl type="hidden" name="salt" value={salt} onChange={calcHash}></FormControl>*/}
            {/*    <FormControl type="hidden" name="txnid" value={txnid} onChange={calcHash}></FormControl>*/}
            {/*    <FormControl type="hidden" name="amount" value={amount} onChange={calcHash}></FormControl>*/}
            {/*    <FormControl type="hidden" name="firstname" value={firstname} onChange={calcHash}></FormControl>*/}
            {/*    <FormControl type="hidden" name="email" value={email} onChange={calcHash}></FormControl>*/}
            {/*    <FormControl type="hidden" name="phone" value={phone} onChange={calcHash}></FormControl>*/}
            {/*    <FormControl type="hidden" name="productinfo" value={productinfo} onChange={calcHash}></FormControl>*/}
            {/*    <FormControl type="hidden" name="surl" value={surl} readOnly></FormControl>*/}
            {/*    <FormControl type="hidden" name="furl" value={furl} readOnly></FormControl>*/}
            {/*    <FormControl type="hidden" name="hash" value={hash} readOnly></FormControl>*/}
            {/*    /!*<button type={'button'} onClick={calcHash} className="btn btn-primary btn-sm btn-block">Save</button>*!/*/}
            {/*    <button type="submit" className="btn btn-success btn-sm btn-block">Pay</button>*/}
            {/*<SabpaisaSdk txtnId={clientTxnId} sabPaisaUrl={sabpaisaUrl}*/}
            {/*             clientCode={clientCode} transUserPassword={transUserPassword}*/}
            {/*             transUserName={transUserName} callbackUrl={''}*/}
            {/*             isOpen={isOpen} authkey={authkey} authiv={authiv}*/}
            {/*             payerName={payerName} payerEmail={payerEmail}*/}
            {/*             payerMobile={payerMobile} payerAddress={payerAddress}*/}
            {/*             amount={amount} udf1={udf1} udf2={udf2}*/}
            {/*             udf3={udf3} udf4={udf4} udf5={udf5}*/}
            {/*             udf6={udf6} udf7={udf6} udf8={udf8}*/}
            {/*             udf9={udf9} udf10={udf10} udf11={udf11}*/}
            {/*             udf12={udf12} udf13={udf13} udf14={udf14}*/}
            {/*             udf15={udf15} udf16={udf16} udf17={udf17}*/}
            {/*             udf18={udf18} udf19={udf19} udf20={udf20}*/}
            {/*             channelId={channelId} programId={programId}*/}
            {/*             mcc={mcc} />*/}
            {/*</Form>*/}

            <form className="main-frm " onSubmit={handleSubmit}>
                <div className="wrapper">
                    <div><h2>Sabpaisa Payment</h2></div>
                    <div id='renderSabPaisa'></div>
                    <div className="frm">
                        <label htmlFor=""> Name : </label> <br/>
                        <input type="text" value={payerName} onChange={(e) => setPayerName(e.target.value)}/>
                    </div>
                    <div className="frm">
                        <label htmlFor=""> Email : </label> <br/>
                        <input type="text" value={payerEmail} onChange={(e) => setPayerEmail(e.target.value)}/>
                    </div>
                    <div className="frm">
                        <label htmlFor=""> Phone : </label> <br/>
                        <input type="text" value={payerMobile} onChange={(e) => setPayerMobile(e.target.value)}/>
                    </div>
                    <div className="frm">
                        <label htmlFor=""> Amount : </label> <br/>
                        <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)}/>
                    </div>
                    <div className="frm">
                        <label htmlFor=""> Address : </label> <br/>
                        <input type="text" value={payerAddress} onChange={(e) => setPayerAddress(e.target.value)}/>
                    </div>
                    <div className="frm">
                        <button type="submit" value="Submit" className="pay-btn">Pay</button>
                    </div>
                </div>
            <SabpaisaPaymentGateway payerName={payerName} payerEmail={payerEmail} payerMobile={payerMobile} isOpen={isOpen} />
            {/*Registration Fee Payment Module*/}
            </form>

        </div>
    )
}

export default MakePaymentReg;