import React, {useEffect, useState} from "react";
import Axios from "axios";
import {useHistory} from "react-router-dom";
import {ReactSession} from "react-client-session";
import dotenv from 'dotenv';

const OffersStream=()=>{
    //dotenv.config();
    const history=useHistory();
    ReactSession.setStoreType("localStorage");
    useEffect(()=>{
        const Role=ReactSession.get("role");
        if(Role!=="mainadmin")
        {
            alert("Please login first");
            history.push('/admin')
        }
        getColleges();
        getStreamOffers();
    },[])

    const [college,setCollege]=useState({
        college_id:0,stream_id:0,
    });//my battery died, will talk here, are you on mute?
    let eleName,value;
    const handleInputs=(e)=>{
        eleName=e.target.name;
        value=e.target.value;
        setCollege({...college,[eleName]:value});
    }

    const[streams,setStreams]=useState([]);
    const getStreamOffers=async ()=>{
        // const college_id=e.target.value;
        const uri=`${process.env.REACT_APP_URI}college/offers`;
        // alert(uri)
        const getclgstream=await Axios.get(uri);
        const data=getclgstream.data;

        setStreams(data);

    }

    const [colleges,setColleges]=useState([]);
    const getColleges=async ()=>{
        const res=await Axios.get(`${process.env.REACT_APP_URI}college`);
        const getclg= res.data;
        setColleges(getclg);
    }

    const[isDisabled,setIsDisabled]=useState(true)
    const getActive=()=>{
        setIsDisabled(false);
    }

    const postStreamCollege=async ()=>{
        alert(`Adding a stream into a college`);
        // e.preventDefault();
        //const {college_id,stream_id}=college;
        //console.log(`${process.env.REACT_APP_URI}college/offer`);
        const result=await Axios.post(`${process.env.REACT_APP_URI}college/offer`,{
            college_id:college.college_id,
            stream_id:college.stream_id,
        }).then((res)=>
        {
                history.push("/admin/success/Add_Stream_to_College/true");
        });
    }
    return(
        <>
            {/*Check Admin Role Here*/}
            {/*Todo Code to check the admin Role */}

            <div className="container">
                <div className="row">
                    <div className="col-md-3">&nbsp;</div>
                    <div className="col-md-6">
                        <div className="card border-secondary mt-3 text-justify">
                            <div className="card-header"><h4 className="card-title"> Add New College</h4></div>
                            <div className="card-body">
                                <div className="container form-floating">
                                    <form method="POST" id="form-college">
                                        <div className="row">
                                            <div className="col-md-12 ">
                                                <label>College Name</label>
                                                <select  name="college_id" className="form-control"
                                                    // onChange={handleInputs} value={selectDefault}
                                                         onChange={handleInputs}
                                                >
                                                    <option value="0">--Select College--</option>
                                                    {colleges.map(item => {
                                                        console.log(item);
                                                        return <option key={item.college_id} value={item.college_id}> {item.college_name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col-md-12 ">
                                                <label className="mt-3">Stream</label>
                                                <select  name="stream_id" className="form-control"
                                                         onChange={handleInputs} //onBlur={handleInputs}
                                                >
                                                    <option value="0">--Select Stream--</option>
                                                    { streams.length>0?
                                                        streams.map(item => {
                                                            ///   console.log(item);
                                                            return <option key={item.stream_id} value={item.stream_id}> {item.stream_name}</option>
                                                        }): <option > No Stream Found</option>
                                                    }
                                                </select>
                                            </div>

                                            <button onClick={postStreamCollege}
                                                    // disabled={false}
                                                    className="btn btn-sm btn-success mt-3 mx-2" > Add Stream</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">&nbsp;</div>
                </div>
            </div>
        </>
    )
}

export default OffersStream;