import React, {useEffect, useState} from "react";
import IsAdmin from "../isAdmin";
import Axios from "axios";
import {useHistory} from "react-router-dom";
import {CSVLink,CSVDownload} from "react-csv";
import {ReactSession} from "react-client-session";

const AdmittedStudents=()=>{
    const history=useHistory();
    ReactSession.setStoreType("localStorage");
    useEffect(()=>{
        // const Role=ReactSession.get("role");
        // if(Role!=="collegeadmin" && Role!=="mainadmin")
        // {
        //     alert("Please login first");
        //     history.push('/admin')
        // }
    },[])
    const [students,setStudents]=useState([{}]);
    const [studentsm,setStudentsm]=useState([{}]);
    const [studentsp,setStudentsp]=useState([{}]);
    const [studentsc,setStudentsc]=useState([{}]);
    const downloadStudentsListArts=async ()=>{
        // alert('Occured')
        const allStudents=await Axios.get(`${process.env.REACT_APP_URI}student/admitted/ARTS`).then((res)=>{
            console.log(res);
            setStudents(res.data);
        }).catch(err=>{
            console.log('Some Error Occured',err)
        })
    }
    const downloadStudentsListPCM=async ()=>{
        // alert('Occured')
        const allStudents=await Axios.get(`${process.env.REACT_APP_URI}student/admitted/PCM`).then((res)=>{
            console.log(res);
            setStudentsm(res.data);
        }).catch(err=>{
            console.log('Some Error Occured',err)
        })
    }
    const downloadStudentsListPCB=async ()=>{
        // alert('Occured')
        const allStudents=await Axios.get(`${process.env.REACT_APP_URI}student/admitted/PCB`).then((res)=>{
            console.log(res);
            setStudentsp(res.data);
        }).catch(err=>{
            console.log('Some Error Occured',err)
        })
    }
    const downloadStudentsListComm=async ()=>{
        // alert('Occured')
        const allStudents=await Axios.get(`${process.env.REACT_APP_URI}student/admitted/COMMERCE`).then((res)=>{
            console.log(res);
            setStudentsc(res.data);
        }).catch(err=>{
            console.log('Some Error Occured',err)
        })
    }
    //list to be finalised
    //Application number, Name, Stream, Amount paid Statement,
    const header=[
        {
            label:"Application Number",key:"application_no"
        },
        {
            label:"Student Name",key:"student_name"
        },
        {
            label:"Father's Name",key:"student_fatherName"
        },
        {
            label:"Mother's Name",key:"student_mothername"
        },
        {
            label:"Student Email",key:"student_email"
        },
        {
            label:"Student Mobile",key:"student_mobile"
        },
        {
            label:"Student Category",key:"category_name"
        },
        {
            label:"Stream",key:"stream_name"
        },
        {
            label:"Subject1",key:"subject1"
        },
        {
            label:"Subject2",key:"subject2"
        },
        {
            label:"Optional",key:"subject3"
        },
        {
            label:"Group Choice",key:"groupsubject"
        },
        {
            label:"Class Roll No", key:"class_rollno"
        }
    ]
    const csvLink={
        filename:"AdmittedStudents.csv",
        headers:header,
        data:students
    }
    const csvLinkm={
        filename:"AdmittedStudentsPCM.csv",
        headers:header,
        data:studentsm
    }
    const csvLinkp={
        filename:"AdmittedStudentsPCB.csv",
        headers:header,
        data:studentsp
    }
    const csvLinkc={
        filename:"AdmittedStudentsComm.csv",
        headers:header,
        data:studentsc
    }
    return(
        <>
            {/*Check Admin Role Here*/}
            {/*Todo Code to check the admin Role */}

            <div className="container">
                <div className="row">
                    <div className="col-md-3">&nbsp;</div>
                    <div className="col-md-6">
                        <div className="card border-secondary mt-3 text-justify">
                            <div className="card-header"><h4 className="card-title"> Download Stream wise Admitted students List</h4></div>
                            <div className="card-body">
                                <div className="container form-floating">
                                    {/*<form method="POST" id="form-stream">*/}
                                        <div className="row">
                                            <button onClick={downloadStudentsListArts} className="btn btn-sm btn-primary mt-3 mx-2" >Students List (Arts)</button>
                                            {
                                                (students.length>1)?
                                                    <CSVLink {...csvLink}>Download Students Details (Arts)</CSVLink>
                                                    :
                                                    ""
                                            }
                                        </div>
                                    <div className="row">
                                        <button onClick={downloadStudentsListPCM} className="btn btn-sm btn-warning mt-3 mx-2" >Students List (PCM)</button>
                                        {
                                            (studentsm.length>1)?
                                                <CSVLink {...csvLinkm}>Download Students Details (PCM)</CSVLink>
                                                :
                                                ""
                                        }
                                    </div>
                                    <div className="row">
                                        <button onClick={downloadStudentsListPCB} className="btn btn-sm btn-danger mt-3 mx-2" >Students List (PCB)</button>
                                        {
                                            (studentsp.length>1)?
                                                <CSVLink {...csvLinkp}>Download Students Details (PCB)</CSVLink>
                                                :
                                                ""
                                        }
                                    </div>
                                    <div className="row">
                                        <button onClick={downloadStudentsListComm} className="btn btn-sm btn-success mt-3 mx-2" >Students List (Commerce)</button>
                                        {
                                            (studentsc.length>1)?
                                                <CSVLink {...csvLinkc} > Download Students Details (Commerce)</CSVLink>
                                                :
                                                ""
                                        }
                                    </div>
                                    {/*</form>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">&nbsp;</div>
                </div>
            </div>
        </>
    )
}

export default AdmittedStudents;