import React,{Component, useState,} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    HashRouter,
    Route,
    Redirect,
    Switch,
    useHistory,
    Link,
    BrowserRouter
} from "react-router-dom";
import Navbar from "./navigation/navbar";
import StudentBasicRegistration from "./components/Student/studentBasicRegistration";
import Home from "./components/Student/Home";
import NewApp from "./components/Student/newApp";
import Error404 from "./components/404";
import StudentProfile from "./components/Student/studentProfile";
import AddCollege from "./components/admin/college/addCollege";
import AddSubjectType from "./components/admin/subject-type/addSubjectType";
import AddAdmin from "./components/admin/collegeadmin/addAdmin";
import AddStream from "./components/admin/stream/addStream";
import AddSubject from "./components/admin/subject/addSubject";
import AddGroup from "./components/admin/groups/addGroup";
import AddCaste from "./components/admin/caste/addCaste";
import UpdateAdmin from "./components/admin/collegeadmin/upAdmin";
import {ContactUS} from "./components/email/email";
import Login from "./components/Student/login";
import StudentDashboard from "./components/Student/dashboard/dashboard";
import convenienceFee from "./components/admin/convenience-fee/convenienceFee";
import AddConvenienceFee from "./components/admin/convenience-fee/convenienceFee";
import RegistrationForm from "./components/Student/dashboard/RegistrationForm";
import AcademicDetails from "./components/Student/academicDetails/academicDetails";
import StudentApplied from "./components/Student/apply/apply";
import ConvFeePayment from "./components/Student/FeePayment/convenienceFee";
import RegFeePayment from "./components/Student/FeePayment/registrationFee";
// import PytmPG from "./components/Student/FeePayment/ptmpg";
import Welcome from "./components/Student/welcome";
// import RazorPayComponent from "./components/Student/FeePayment/razorpay/razorpay";
import Success from "./components/admin/success";
import AdminDashboard from "./components/admin/dashboard";
import AddSubjecttoGroup from "./components/admin/groups/addSubject";
import PaymentPayU from "./components/Student/FeePayment/payu/payufront";
// import payuresponse from "./components/Student/FeePayment/payu/payuresponse";
import PayUresponse from "./components/Student/FeePayment/payu/payuresponse";
import AdminLogin from "./components/admin/login/login";
import AddRegistrationFee from "./components/admin/registration-fee/registrationFee";
import AddFee from "./components/admin/admission-fee/admission";
import AddCategory from "./components/admin/category/addCategory";
// import MakePayment from "./components/Student/FeePayment/payu/redirectToPay";
import MakePaymentReg from "./components/Student/FeePayment/payu/redirectToPay";
import MakePaymentConv from "./components/Student/FeePayment/payu/MakePaymentConv";
// import StudentDetailed from "./components/Student/studentDetailed";
import StudentDetail from "./components/Student/studentDetailed";
import PreviousSchool from "./components/Student/prevSchool/previousSchool";
import ConfirmStudentDetail from "./components/Student/confirm/confirm";
import MainAdminLogin from "./components/admin/login/loginmain";
import OffersStream from "./components/admin/college/offers";
import PrintApplication from "./components/Student/confirm/printApplication";
import ForgotPassword from "./components/Student/dashboard/ForgotPassword";
import Footer from "./navigation/footer";
import ViewStudentDetail from "./components/Student/confirm/viewDetails";
import ConfirmDetail from "./components/Student/admission/confirmDetails";
import ProofUpload from "./components/Student/admission/proofUpload";
// import MakePaymentAdm from "./components/Student/FeePayment/payu/MakePaymentAdmission";
import AdmFeePayment from "./components/Student/FeePayment/admissionFee";
import MakePaymentAdmission from "./components/Student/FeePayment/payu/MakePaymentAdmission";
import PrintAdmission from "./components/Student/admission/printAdmission";
import RegisteredStudents from "./components/admin/student/registeredStudents";
import AdmittedStudents from "./components/admin/student/admittedStudents";
import AdmissionAllowed from "./components/admin/student/admissionAllowed";
import RollNumberUpdate from "./components/admin/student/rnallocation";
import RollNumberUpdate1 from "./components/admin/student/rnallocation1";
import getFeeStatus from "./components/admin/student/getFeeStatus";
import getAdmissionDetails from "./components/admin/student/getAdmissionDetails";
import MakePaymentRegistration from "./components/Student/FeePayment/payu/MakePaymentRegistration";
import RegistrationFeeOne from "./components/Student/FeePayment/registrationFee1";
import Registration from "./components/Student/registration/registration";
import Explicitadmission from "./components/admin/student/explicitadmission";
import Sidebar from "./components/sidebar/Sidebar";
import UploadCollegeStudentBulk from "./components/admin/upload/collegestudent";
import CollegeStudentLogin from "./components/collegeStudent/login";



function App(){
    let history=useHistory()
    return(
        <>
            <BrowserRouter>
                <Navbar/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-3"><Sidebar/></div>
                        <div className="col-9">


                            <Switch>
                                <Route exact path="/welcome" component={Welcome}/>
                                {/*Student Block for Admission Process*/}
                                <Route exact path="/" component={StudentBasicRegistration}/>
                                {/*College Student Login */}
                                <Route exact path={"/student"} component={CollegeStudentLogin}/>
                                <Route exact path="/newApp" component={NewApp}/>
                                <Route exact path="/student/profile/update/:id" component={StudentDetail}/>
                                {/*Student Dashboard*/}
                                <Route exact path="/student/dashboard" component={StudentDashboard}/>
                                {/*Login Student*/}
                                <Route exact path="/student/login" component={Login}/>
                                <Route exact path="/student/profile/:id" component={StudentProfile}/>
                                {/*<Route exact path="/student/home" component={()=><Home/>}/>*/}
                                {/*<Route exact path="/student/convenience-fee" component={getConvenienceFeeDetails}/>*/}
                                {/*<Route exact path="/student/registration" component={RegistrationForm}/>*/}
                                <Route exact path="/student/previous/academic" component={AcademicDetails}/>
                                <Route exact path="/student/previous/school" component={PreviousSchool}/>
                                {/*Student Applied for the Stream*/}
                                <Route exact path="/student/applied/stream" component={StudentApplied}/>
                                <Route exact path="/student/confirm/detail" component={ConfirmStudentDetail}/>
                                <Route exact path="/student/view/detail" component={ViewStudentDetail}/>
                                <Route exact path="/student/print/application/:college_id/:strid" component={PrintApplication}/>
                                {/*Convenience Fee Payment module*/}
                                {/*Payment Routes*/}
                                {/*<Route exact path="/payment/student" component={PaymentPayU}/>*/}
                                <Route exact path="/forgot/password" component={ForgotPassword}/>
                                <Route exact path="/payment/payu/response/:status" component={PayUresponse}/>
                                <Route exact path="/fee/payu/response" component={PayUresponse}/>
                                <Route exact path="/student/ConvFee/Payment" component={ConvFeePayment}/>
                                <Route exact path="/student/confirm/adm/detail/:clgid/:strid/:stdid" component={ConfirmDetail}/>
                                <Route exact path="/student/upload/proof" component={ProofUpload}/>
                                {/*Registration Fee Payment module*/}
                                <Route exact path="/student/RegFee/Payment/:stid/:strid/:clgid" component={RegFeePayment}/>
                                {/*Convenience Fee Payment module*/}
                                <Route exact path="/student/ConvFee/Payment/:stid/:clgid" component={ConvFeePayment}/>
                                <Route exact path="/student/AdmFee/Payment/:stid/:clgid/:strid/:catid" component={AdmFeePayment}/>
                                {/*Make payment online through payu money*/}
                                <Route exact path="/payu/make/payment" component={MakePaymentReg}/>
                                <Route exact path="/payu/make/payment/adm" component={MakePaymentAdmission}/>
                                <Route exact path="/payu/make/payment/conv" component={MakePaymentConv}/>
                                <Route exact path="/student/add/caste" component={AddCaste}/>
                                <Route exact path="/print/admission/form/:stid/:clgid/:strid" component={PrintAdmission}/>

                                {/*Registration Payment*/}
                                <Route exact path="/payu/make/payment/reg" component={MakePaymentRegistration}/>
                                <Route exact path="/student/regp/fee/:stid/:clgid" component={RegistrationFeeOne}/>

                                {/*Email Realted Services*/}
                                <Route exact path="/admin/email" component={ContactUS}/>
                                <Route exact path="/admin/get/AdmissionDetails" component={getAdmissionDetails}/>
                                <Route exact path="/student/print/registration/detail" component={Registration}/>
                                <Route exact path="/update/student/rollno" component={RollNumberUpdate1}/>
                                <Route exact path="/view/student/registration/chart" component={RollNumberUpdate1}/>



                                {/*All Admin Routes*/}
                                <Route exact path="/admin" component={AdminLogin}/>
                                <Route exact path="/admin/main" component={MainAdminLogin}/>
                                <Route exact path="/admin/addCollege" component={AddCollege}/>
                                <Route exact path="/admin/addSubjectType" component={AddSubjectType}/>
                                <Route exact path="/admin/addAdmin" component={AddAdmin}/>{/*College list fetch, pending work*/}
                                <Route exact path="/admin/upAdmin/:id" component={UpdateAdmin}/>
                                <Route exact path="/admin/addStream" component={AddStream}/>
                                <Route exact path="/admin/addSubject" component={AddSubject}/>
                                <Route exact path="/admin/addGroup" component={AddGroup}/>
                                <Route exact path="/admin/addCaste" component={AddCaste}/>
                                <Route exact path="/admin/success/:name/:isSuccess" component={Success}/>
                                <Route exact path="/admin/dashboard" component={AdminDashboard}/>
                                <Route exact path="/admin/subject/group" component={AddSubjecttoGroup}/>
                                <Route exact path="/admin/add/convfee" component={AddConvenienceFee}/>
                                <Route exact path="/admin/add/regfee" component={AddRegistrationFee}/>
                                <Route exact path="/admin/add/fee" component={AddFee}/>
                                <Route exact path="/admin/add/category" component={AddCategory}/>
                                <Route exact path="/admin/college/offers" component={OffersStream}/>
                                <Route exact path="/admin/student/list" component={RegisteredStudents}/>
                                <Route exact path="/admin/student/list/admitted" component={AdmittedStudents}/>
                                <Route exact path="/admin/student/allowed" component={AdmissionAllowed}/>
                                <Route exact path="/admin/student/rollno" component={RollNumberUpdate}/>
                                <Route exact path="/admin/student/fee/status" component={getFeeStatus}/>
                                <Route exact path="/admin/update/fee/npg" component={Explicitadmission}/>
                                <Route exact path="/admin/upload/student/csv" component={UploadCollegeStudentBulk}/>
                                {/*/admin/student/fee/status*/}


                                {/*All Student's Routes*/}



                                <Route path="*" component={()=><Error404/>}/>
                            </Switch>

                        </div>
                    </div>
                </div>
                {/*<RazorPayComponent/>*/}

            </BrowserRouter>
            <Footer/>

        </>
    )
}


export default App;
