import React from "react";
import {useState} from "react";
import axios from 'axios';
import Axios from "axios";
import {ReactSession} from "react-client-session";
const FileUpload= ()=>{
    ReactSession.setStoreType("localStorage")
    const [photo,setPhoto]=useState('')
    const [certi,setCerti]=useState('')
    const [migr,setMigr]=useState('')
    const [sign,setSign]=useState('')
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
        const onFormSubmit=async (e)=>{
            e.preventDefault();
            const formData=new FormData();
            formData.append('photo',file);
            // console.log(formData);
            const config={
                headers:{
                    'content-type':'multipart/form-data'
                }
            };
            await Axios.post(`${process.env.REACT_APP_URI}student/upload/photo`,formData,config).then((response)=>{
                console.log(response.data)
                if(response.data.success=="success")
                {
                    alert('Photo uploaded successfully');
                }else{
                    alert('Either not an Image or Size is not in between 20KB and 30KB (300x400). Please select right image to upload your photo.');
                    return;
                }
                ReactSession.set("photo",response.data.photoPath);
                setPhoto(response.data.photoPath);
            }).catch(err=>{
                console.log(err)
            })
        }

    const onFormSubmitCerti=async (e)=>{
        e.preventDefault();
        const formData=new FormData();
        formData.append('certi',file);
        const config={
            headers:{
                'content-type':'multipart/form-data'
            }
        };
        await Axios.post(`${process.env.REACT_APP_URI}student/upload/certificate`,formData,config).then((response)=>{
            console.log(response.data)
            if(response.data.success=="success")
            {
                alert('Certificate uploaded successfully');
            }else{
                alert('Either not a PDF file or size is not in between 130KB and 140KB. Only pdf file accepted.');
                return;
            }
            ReactSession.set("certi",response.data.certiPath);
            setCerti(response.data.certiPath);
        }).catch(err=>{
            console.log(err)
        })
    }

    const onFormSubmitMigr=async (e)=>{
        e.preventDefault();
        const formData=new FormData();
        formData.append('migr',file);
        const config={
            headers:{
                'content-type':'multipart/form-data'
            }
        };
        await Axios.post(`${process.env.REACT_APP_URI}student/upload/migration`,formData,config).then((response)=>{
            if(response.data.success=="success")
            {
                alert('Migration Certificate uploaded successfully');
            }else{
                alert('Either not a PDF file or size is not in between 130KB and 140KB. Only pdf file accepted.');
                return;
            }
            console.log(response.data)
            ReactSession.set("migr",response.data.migrationPath);
            setMigr(response.data.migrationPath);
        }).catch(err=>{
            console.log(err)
        })
    }

    const onFormSubmitSign=async (e)=>{
        e.preventDefault();
        const formData=new FormData();
        formData.append('sign',file);
        const config={
            headers:{
                'content-type':'multipart/form-data'
            }
        };
        await Axios.post(`${process.env.REACT_APP_URI}student/upload/signature`,formData,config).then((response)=>{

            console.log(response.data)
            if(response.data.success=="success")
            {
                alert('Signature uploaded successfully');
            }else{
                alert('Either not an Image or Size is not in between 14KB-20KB (300x200). Please select right image to upload your sign.');
                return;
            }
            ReactSession.set("sign",response.data.signPath);
            setSign(response.data.signPath);
        }).catch(err=>{
            console.log(err)
        })
    }

    const onInputChange=(e)=>{
            setFile(e.target.files[0]);
        }

        const myImage={
            border:"1px solid #999",
            minHeight:"160px",
            maxHeight:"160px",
            minWidth:"130px",
            maxWidth:"130px",
            marginTop:"10px",
            marginBottom:"10px"
        }

    const mySign={
        border:"1px solid #999",
        minHeight:"80px",
        maxHeight:"80px",
        minWidth:"180px",
        maxWidth:"180px",
        marginTop:"90px",
        marginBottom:"10px"
    }
        const margin={
            marginBottom: "10px"
        }
        const [photow,setPhotoW]=useState(0)
        const [photoh,setPhotoH]=useState(0)
        const onPhotoLoad=({target:img})=>{
            setPhotoW(img.offsetWidth);
            setPhotoH(img.offsetHeight);
        }
        return (
            <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        {
                                <img src={
                                    (photo || ReactSession.get("photo")) ? `${process.env.REACT_APP_URI}images/photo/${(photo || ReactSession.get("photo"))}`
                                        :
                                        photo
                                } style={myImage} onLoad={onPhotoLoad}/>
                        }
                        <form onSubmit={onFormSubmit}>
                            <input type='file' name='photo' style={margin} onChange={onInputChange} className="form-control" required={true} />
                            <button type='submit' className="btn btn-sm btn-primary mb-1">Upload Photo</button>
                        </form>
                    </div>
                    <div className="col-md-3">
                        <img src={
                            (sign || ReactSession.get("sign"))?`${process.env.REACT_APP_URI}images/sign/${(sign || ReactSession.get("sign"))}`
                                :
                                ""
                        } style={mySign} />
                        <form onSubmit={onFormSubmitSign}>
                            <input type='file' name='sign' style={margin} onChange={onInputChange} className="form-control" required={true} />
                            <button type='submit' className="btn btn-sm btn-warning mb-1">Upload Signature</button>
                        </form>
                    </div>
                    <div className="col-md-3">

                        {
                            (certi || ReactSession.get("certi"))?
                                <i className="fa fa-file-pdf fa-10x text-danger" style={myImage}></i>:""
                        }
                        <form onSubmit={onFormSubmitCerti}>
                            <input type='file' name='certi' style={margin} onChange={onInputChange} className="form-control"  />
                            <button type='submit' className="btn btn-sm btn-success  mb-1">Upload Certificate</button>
                        </form>
                    </div>
                    <div className="col-md-3">
                        {
                            (migr || ReactSession.get("migr"))?
                                <i className="fa fa-file-pdf fa-10x text-danger" style={myImage}></i>:""
                        }
                        <form onSubmit={onFormSubmitMigr}>
                            <input type='file' name='migr' style={margin} onChange={onInputChange} className="form-control"  />
                            <button type='submit' className="btn btn-sm btn-secondary  mb-1">Upload Migration</button>
                        </form>
                    </div>

                </div>
            </div>
            </div>
        );

}

export default FileUpload;