import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Axios from "axios";
import {ReactSession} from "react-client-session";
import ArrowHeader from "../arrowHeader";
import {IsValidLogin} from "../isLoggedIn";
import dateFormat from "dateformat";
import FileUpload from "./fileupload";
// import {useHistory} from "react-router";
// import {withRouter} from "react-router-dom";

const ProofUpload=props=>{
    const history=useHistory();
    ReactSession.setStoreType("localStorage");

    useEffect(()=>{
        if(!IsValidLogin()){
            // console.log("entered");
            localStorage.clear();
            history.push("/");
        }
        getAdmissionStatus();
        alert("Please check the details afterwards no change will be allowed.")
        getStudentBasic();
        getCategories();
        getStudentDetail();

        // console.log(admissionStatus)

    },[])
    const [student,setStudent]=useState({
        student_name:"",student_fathername:"",student_dob:"",student_gender:"",
        student_mobile:"",student_email:"",student_batch:"",student_id:0
    });

    const [admissionStatus, setAdmissionStatus]=useState(false)
    const getAdmissionStatus=async ()=>{
        const student_id=ReactSession.get("student_id");
        const docs=await Axios.get(`${process.env.REACT_APP_URI}student/doc/detail/${ReactSession.get("student_id")}`);
        const admission=await Axios.get(`${process.env.REACT_APP_URI}student/get/admission/fee/status/${ReactSession.get("student_id")}/${ReactSession.get("college_id")}/${ReactSession.get("stream_id")}`)
            .then((res)=>{
                console.log(res);
                if((res.data.isPaidAdmFee)&&(docs.length>0))
                {
                    alert('You have already paid for this stream.')
                    history.push(`/print/admission/form/${ReactSession.get("student_id")}/${ReactSession.get("college_id")}/${ReactSession.get("stream_id")}`)
                }else{
                    if(window.confirm('Are you want to pay the Admission fee?')){
                        history.push(`/student/AdmFee/Payment/${ReactSession.get("student_id")}/${ReactSession.get("college_id")}/${ReactSession.get("stream_id")}/${ReactSession.get("student_category")}`);
                    }
                }
            }).catch(err=>{
                alert('Some Error Occured')
                console.log(err.message);
            })
    }

    const[studentd,setStudentd]=useState({
        student_mothername:"", student_caste:"",student_address:"",student_aadhar:"",
        student_pwd:"",student_nationality:"",student_religion:"",
    })
    //let errorsObj={stduent_email:'',student_password:''};
    //const [errors,setErrors]=useState(errorsObj);

    let eleName,value;
    const handleInputs=(e)=>{
        eleName=e.target.name;
        value=e.target.value.toUpperCase();
        setStudentd({...studentd,[eleName]:value});
    }

    const handleInput=(e)=>{
        eleName=e.target.name;
        value=e.target.value.toUpperCase();
        setStudent({...student,[eleName]:value});
    }
    const isDocumentUploaded=useState(false)
    const getDocuments=async ()=>{
        const allDocs=Axios.get(`${process.env.REACT_APP_URI}student/documents`).then((res)=>{
            setCategories(res.data);
        }).catch(err=>{
            console.log(err.message);
        });
        if(allDocs.length>0)
        {
            alert('All Documents have been uploaded. You may update the exisitng Document.')
        }

        // console.log(categories);
    }
    const[categories,setCategories]=useState([{}]);
    const getCategories=async ()=>{
        const allCastes=await Axios.get(`${process.env.REACT_APP_URI}caste/category`).then((res)=>{
            setCategories(res.data);
        }).catch(err=>{
            console.log(err.message);
        });
        console.log(categories);
    }
    const getStudentBasic=async ()=>{
        const student_id=ReactSession.get("student_id");
        const student1=await Axios.get(`${process.env.REACT_APP_URI}student/byid/${student_id}`).then((res)=>{
            setStudent(res.data);
            console.log(res.data);
        });
        console.log(student);
    }

    const getStudentDetail=async ()=>{
        const student_id=ReactSession.get("student_id");
        const student1=await Axios.get(`${process.env.REACT_APP_URI}student/detail/id/${student_id}`).then((res)=>{
            setStudentd(res.data);
            console.log(res.data);
        });
        console.log(student);
    }

    const postImageDetails=async ()=>{
        const uri=`${process.env.REACT_APP_URI}student/updated/photo/details`;
        // debugger
        // alert(uri);
        if(ReactSession.get("photo")==="" || ReactSession.get("photo")===null || ReactSession.get("photo")===undefined){
            alert("Please select a photo.")
            return;
        }
        // if(ReactSession.get("certi")==="" || ReactSession.get("certi")===null || ReactSession.get("certi")===undefined){
        //     alert("Please select a Certificate.")
        //     return;
        // }
        // if(ReactSession.get("migr")==="" || ReactSession.get("migr")===null || ReactSession.get("migr")===undefined){
        //     alert("Please select a Migration Certificate.")
        //     return;
        // }
        if(ReactSession.get("sign")==="" || ReactSession.get("sign")===null || ReactSession.get("sign")===undefined){
            alert("Please select a signature.")
            return;
        }
        console.log('test')
        await Axios.post(uri,{
            student_id:ReactSession.get("student_id"),
            photo:ReactSession.get("photo"),
            certi:ReactSession.get("certi"),
            migr:ReactSession.get("migr"),
            sign:ReactSession.get("sign"),
        }).then(
            (response)=>{
                console.log(response)
                if(response.data.message)
                {
                    alert(response.data.message)
                }
                else{
                    console.log(response)
                }
                //:stid/:clgid/:strid
                history.push(`/student/AdmFee/Payment/${ReactSession.get("student_id")}/${ReactSession.get("college_id")}/${ReactSession.get("stream_id")}/${ReactSession.get("student_category")}`)
            }
        ).catch(err=>{
            console.log(err)
        })
    }

    return(
        <>
            <ArrowHeader form="detail"  />
            <div className="container">
                <div className="row">
                    {/*<div className="col-md-1">&nbsp;</div>*/}
                    <div className="col-md-12">
                        <div className="card border-secondary mt-3 text-justify">
                            <div className="card-header"><h4 className="card-title"> Student's Personal Details</h4></div>
                            <div className="card-body">
                                <div className="alert alert-dismissible alert-primary">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert"></button>
                                    <strong>Note:</strong><br/>
                                    1. Please upload your latest passport size photograph. (300x400) max upload size 20 KB <br/>
                                    2. Upload your caste certificate, if your category is not GENERAL. max upload size 130 KB<br/>
                                    3. Upload your Migration Certificate.  max upload size 130 KB<br/>
                                    4. Upload your scanned signature.  (300x200) max upload size 14 KB
                                </div>
                                <div className="container form-floating">

                                        <div className="row">
                                            <div className="col-md-12 border">
                                                <label>Upload Photo: </label><br/>
                                                <FileUpload/>
                                            </div>
                                        </div>
                                </div>
                                <div className="container form-floating">
                                    <form id="form-studentdetail" onSubmit={postImageDetails} >
                                    <div className="row">

                                            <div className="col-md-6 ">
                                                <label>Student Name: </label>
                                                <label  name="student_name" className={"fw-bold"}>&nbsp; {student.student_name} </label>
                                            </div>
                                            <div className="col-md-6 ">
                                                <label >Father's Name:</label>
                                                <label name="student_fatherName" className="fw-bold"
                                                >&nbsp; {student.student_fatherName}</label>
                                            </div>
                                            <div className="col-md-5 ">
                                                <label className="mt-3">Mother's Name</label>
                                                <label name="student_mothername" className="fw-bold"
                                                >{studentd.student_mothername} </label>
                                            </div>
                                            {/*get the list of caste*/}
                                            {/*<div className="col-md-3 ">*/}
                                            {/*    <label className="mt-3">Caste Category</label>*/}
                                            {/*    <label name="student_caste" className="fw-bold">*/}
                                            {/*           &nbsp; {studentd.student_caste}*/}

                                            {/*    </label>*/}
                                            {/*</div>*/}

                                            <div className="col-md-4 ">
                                                <label className="mt-3">Physical Handicap: </label>
                                                <label name="student_pwd" className="fw-bold">
                                                        &nbsp; {studentd.student_pwd}

                                                </label>
                                            </div>

                                            <div className="col-md-3 ">
                                                <label className="mt-3">Religion: </label>
                                                <label name="student_religion" className="fw-bold"
                                                        >&nbsp;{studentd.student_religion}

                                                </label>
                                            </div>
                                            <div className="col-md-3 ">
                                                <label className="mt-3">Nationality</label>
                                                <label name="student_nationality" className="fw-bold"
                                                        >&nbsp;{studentd.student_nationality}
                                                </label>
                                            </div>
                                            <div className="col-md-3 ">
                                                <label className="mt-3">Aadhar Number:</label>
                                                <label name="student_aadhar" className="fw-bold"
                                                >&nbsp;{studentd.student_aadhar}</label>
                                            </div>
                                            {
                                                // alert(student.student_dob)
                                            }
                                            <div className="col-md-3 ">
                                                <label className="mt-3">Date of Birth:</label>
                                                <label name="student_dob" className="fw-bold ">
                                                    &nbsp;{dateFormat(student.student_dob,"dd/mm/yyyy")} </label>
                                            </div>
                                            <div className="col-md-3 ">
                                                <label className="mt-3">Gender:</label>
                                                <label name="student_gender" className="fw-bold"
                                                >&nbsp;{student.student_gender}
                                                </label>
                                                {/*<input type={"text"} name="student_gender" className="form-control"*/}
                                                {/*       value={student.student_gender}*/}

                                                {/*/>*/}

                                            </div>

                                            <div className="col-md-3 ">
                                                <label className="mt-3">Mobile No.</label>
                                                <label name="student_mobile" className="fw-bold"
                                                >&nbsp;{student.student_mobile} </label>
                                            </div>
                                            <div className="col-md-3 ">
                                                <label className="mt-3">Email ID</label>
                                                <label name="student_email" className="fw-bold"
                                                >&nbsp;{student.student_email} </label>
                                            </div>

                                            <div className="col-md-12">
                                                <label className="mt-3">Address:</label>
                                                <label  name="student_address" className="fw-bold"
                                                >&nbsp;{studentd.student_address} </label>

                                            </div>

                                            <button  type="submit"  className="btn btn-sm btn-primary mt-3 mx-2 col-md-4" ><i className="fa fa-save"></i> Save Information</button>
                                            {/*</form>*/}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="col-md-1">&nbsp;</div>*/}
                </div>
            </div>
        </>
    )
}

export default ProofUpload;