import React, {useEffect, useState} from "react";
import {Link, Redirect, Route, Router, useHistory} from "react-router-dom";
import Axios from "axios";
import env from 'react-dotenv';
import Home from "./Home";
import NewApp from "./newApp";
import {Navigate} from "react-router";
import {ReactSession} from "react-client-session";
import {IsValidLogin} from "./isLoggedIn";
import {colours} from "nodemon/lib/config/defaults";
//dotenv.config();

const StudentBasicRegistration=()=>{
    const [loginStatus, setLoginStatus]=useState(false);
    const [student,setStudent]=useState({student_email:"",student_password:""});
    // const [student_password,setStudent_Password]=useState('');

    // const submitHandler=(e)=>{
    //     e.preventDefault();
    //     onStudentLogin(e)
    // }
    let history=useHistory();
    let eleName,value;
    ReactSession.setStoreType("localStorage");
    const handleInputs=(e)=>{
        eleName=e.target.name;
        value=e.target.value;
        setStudent({...student,[eleName]:value});
    }
    // const handleInputs1=(e)=>{
    //     eleName=e.target.name;
    //     value=e.target.value;
    //     setStudent({...student,[eleName]:value});
    // }
    useEffect(()=>{
        if(!IsValidLogin()){
            // console.log("entered");
            localStorage.clear();
            history.push("/");
        }
        if(ReactSession.get("login_status")==="true")
        {
            // localStorage.clear();
            // history.push("/");
            history.push("/student/dashboard");
        }
        // setLoginStatus(false);
    },[])
    const onStudentLogin=(e)=>{
        e.preventDefault();
        // alert(`${process.env.REACT_APP_URI}`)
        Axios.post(`${process.env.REACT_APP_URI}auth/student/login`,{
            student_email:student.student_email,
            student_password:student.student_password
        }).then((res)=>{
            console.log(res)
            if(res.data.auth)
            {
                setLoginStatus(true);
                localStorage.clear();
                ReactSession.set("login_status",true);
                ReactSession.set("student_id",res.data.data.student_id);
                ReactSession.set("student_name",res.data.data.student_name);
                history.push("/student/dashboard");
            }else{
                alert("Invalid Credentials");
                return (<Redirect to="/"/>)
            }
        }).catch(err=>{
            console.log(err);
        })
    }

    const forgotPassword=()=>{
        history.push("/forgot/password");
    }
    const st1={
        color: '#ffff00'
    }
    const shadow={
        backgroundColor:'#166fe5',
        boxShadow:'2px 5px 5px #999'
    }
    const myStyle={
        color:'#fff',
    }
    return(
        <div className="container" >
            <div className="row">
                <div className="col-md-12">

                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    {/*<a href={"pdf/2ND MERIT LIST_25_AUG_22.pdf"} className="btn btn-warning btn-sm mt-5 btn-block" style={{display:"block"}}> <span style={{color:"#ff0000"}}><i className="fa fa-file-pdf fa-2x"></i></span> 2ND MERIT LIST 25 AUG 2022 </a>*/}
                    {/*<a href={"pdf/2ND MERIT LIST_25_AUG_22 _ APPLIED AFTER 5TH AUG 22.pdf"} className="btn btn-primary btn-sm mt-4 btn-block" style={{display:"block"}}><span style={{color:"#ff0000"}}><i className="fa fa-file-pdf fa-2x"></i></span> 2ND MERIT LIST 25 AUG 2022 <br/> APPLIED AFTER 5TH AUG 22</a>*/}
                    {/*<a href={"pdf/OBC -I MERIT LIST.pdf"} className="btn btn-outline-warning btn-sm mt-4 btn-block" style={{display:"block"}}><span style={{color:"#ff0000"}}><i className="fa fa-file-pdf fa-2x"></i></span> OBC -I MERIT LIST</a>*/}
                    {/*<a href={"pdf/OBC -II MERIT LIST.pdf"} className="btn btn-outline-danger btn-sm mt-4 btn-block" style={{display:"block"}}><span style={{color:"#ff0000"}}><i className="fa fa-file-pdf fa-2x"></i></span> OBC -II MERIT LIST</a>*/}
                </div>
                <div className="col-md-4">
                    <div className="card border-info mt-5 text-justify" style={shadow}>
                        <div className="card-header text-center"><h5 className="card-title text-light"> Sign In
                           </h5>
                            <span className={"fs-6 text-warning "}>
                            <Link to="/newApp" style={st1} >New user click here to register</Link>
                        </span></div>
                        <div className="card-body">
                            {/*<h6 className="card-title">Step 2: Login to Apply </h6>*/}
                            <div className="container mt-1">
                                <div className="row">
                                    <form onSubmit={onStudentLogin}>
                                    <div className="col-md-12 ">
                                        <label className="text-light"><i className="fa fa-user"></i> Student Email </label>
                                        <input type="email" className="form-control" name="student_email"
                                               value={student.student_name} onChange={handleInputs} required={true}
                                        />
                                        <label className="mt-3 text-light" ><i className="fa fa-key"></i> Password:</label>
                                        <input type="password" className="form-control" name="student_password"
                                               value={student.student_password} onChange={handleInputs} required={true}
                                        />
                                        <button type="submit" className="btn btn-sm btn-warning mt-3 mx-2" >Login</button>
                                        <button className="btn btn-sm btn-warning mt-3 mx-2" onClick={forgotPassword}>Forgot Password</button>
                                        <div className="container mt-4">
                                                                                 </div>


                                    </div>
                                    </form>
                                    <small style={myStyle}>For help mail - <a href={"mailto:helpdesk42008@gmail.com"} style={st1}>helpdesk42008@gmail.com</a></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<Route exact path="/">*/}
                {/*    {loginStatus?<Redirect to="/student/home" component={()=><Home/>} />:<NewApp/>}*/}
                {/*</Route>*/}
                <div className="col-md-4">

                </div>
            </div>
        </div>

    )
}

export default StudentBasicRegistration;