import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import {ReactSession} from "react-client-session";
import dotenv from "dotenv";
import dateFormat from "dateformat";
import {useHistory} from "react-router-dom";
import {IsValidLogin} from "../isLoggedIn";
dotenv.config();

const ConfirmStudentDetail=(props)=>{
    const history=useHistory();
    const [regID,setRegID]=useState('');
    ReactSession.setStoreType("localStorage");
    const [studentDetail,setStudentDetail]=useState({});
    const [studentb,setStudentB]=useState({});
    const [studentd,setStudentD]=useState({});
    const [convFee,setConvFee]=useState([{}]);
    const [regFee,setRegFee]=useState([{}]);
    const [regId,setRegId]=useState('');
    const [confirmEnabled,setConfirmEnabled]=useState(false);
    useEffect(()=>{
        if(!IsValidLogin()){
            // console.log("entered");
            localStorage.clear();
            history.push("/");
        }
        getStudentDetail();
        setStudentB(studentDetail.studentb);
        setStudentD(studentDetail.studentd);

    },[]);
    const getAllDetails=()=>{
console.log(studentb)
    }

    const setConfirm=async ()=>{
        //code to set confirm
        var regid='';
        alert('I admit that all the information are corect as per my knowledge.');
        const stud=await Axios.put(`${process.env.REACT_APP_URI}student/confirm/detail/${ReactSession.get("student_id")}`,
            {
                student_confirmation:true,
            })
            .then((res)=>{
                console.log(res)
                history.push("/student/dashboard");
            }).catch(err=>{
                console.log(err.message);
            })
        await Axios.post(`${process.env.REACT_APP_URI}student/stream/apply`,{
            student_id:ReactSession.get("student_id"),
            stream_id:ReactSession.get("streamid"),
            college_id:ReactSession.get("collegeid"),
            registration_fee_paid:'true',
            registration_fee_amount:ReactSession.get("amount"),
            core1_id:ReactSession.get("core1_id"),
            core2_id:ReactSession.get("core2_id"),
            optional1_id:ReactSession.get("optional1_id"),
            group_id:ReactSession.get("group_id"),
        }).then((result)=>{
            console.log(result);
        });
        // await Axios.put(`${process.env.REACT_APP_URI}student/regid/${ReactSession.get("student_id")}`,{
        //     //student_regid:ReactSession.get("regid")
        // })
    }

    const getStudentDetail=async ()=>{
        // alert('get details')
        //code to get student complete details with a single api request
        const uri=`${process.env.REACT_APP_URI}student/confirm/detail/${ReactSession.get("student_id")}`;
        console.log(uri)
        const studentDetails=await Axios.get(`${uri}`)
            .then((res)=>{
                console.log(res.data);
                var {studentb,studentd,conFee,prosFee}=res.data;
                ReactSession.set("Category",res.data.prosFee[0].category_name);
                setStudentDetail(res.data);
                setConvFee(conFee);
                setStudentB(studentb);
                setStudentD(studentd);
                setRegFee(prosFee);

           // //  setStudentDetail(res.data);
           // // setConvFee(res.data.conFee);
           //  console.log(conFee)
        }).catch(err=>{
            console.log(err.message);
            })
    }
    return(
        <>
            <div className="container mt-4">
                <div className="row p-2">
                    <div className="col-md-12">
                        <fieldset className="border">
                            {
                                studentb?
                                    <></>:
                                    <h1>Please wait.. we're fetching the data</h1>
                            }


                            <legend className="fw-bold">Student Confirmation Details
                            </legend>
                            {/*<button onClick={getAllDetails}> Click me to get complete detail</button>*/}
                            <div className="border">
                            <label className="col-md-3 mt-3" htmlFor="name">Student Name:</label>
                            {
                                studentb?
                                    <label className="fw-bold text-dark col-md-9 mt-3">{studentb.student_name}</label>
                                    :
                                    <label></label>
                            }
                            <label className="col-md-3 mt-3" htmlFor="name">Father's Name:</label>
                            {
                                studentb?
                                    <label className="fw-bold text-dark col-md-9 mt-3">{studentb.student_fatherName}</label>
                                    :
                                    <label></label>
                            }
                                <label className="col-md-3 mt-3" htmlFor="name">Mother's Name:</label>
                                {
                                    studentd?
                                        <label className="fw-bold text-dark col-md-9 mt-3">{studentd.student_mothername}</label>
                                        :
                                        <label></label>
                                }
                            </div>
                            <div className="bg-secondary text-light fw-bold p-2">
                            <label >
                                Personal Details
                            </label>
                            </div>
                            <div className="border">
                            <label className="col-md-2 mt-2" htmlFor="name">Date of Birth:</label>
                            {
                                studentb?
                                    <label className="fw-bold text-dark col-md-4 mt-2">{dateFormat(studentb.student_dob,"dd-mm-yyyy")}</label>
                                    :
                                    <label></label>
                            }
                            <label className="col-md-2 mt-2" htmlFor="name">Gender:</label>
                            {
                                studentb?
                                    <label className="fw-bold text-dark col-md-4 mt-2">{studentb.student_gender}</label>
                                    :
                                    <label></label>
                            }
                            <label className="col-md-2 mt-2" htmlFor="name">Mobile:</label>
                            {
                                studentb?
                                    <label className="fw-bold text-dark col-md-4 mt-2">{studentb.student_mobile}</label>
                                    :
                                    <label></label>
                            }
                            <label className="col-md-2 mt-2" htmlFor="name">Email:</label>
                            {
                                studentb?
                                    <label className="fw-bold text-dark col-md-4 mt-2">{studentb.student_email}</label>
                                    :
                                    <label></label>
                            }
                                <label className="col-md-2 mt-2" htmlFor="name">Caste:</label>
                                {
                                    regFee?
                                        <label className="fw-bold text-dark col-md-9 ">{regFee[0].category_name}</label>
                                        :
                                        <label></label>
                                }
                                <label className="col-md-2 mt-2" htmlFor="name">Aadhar:</label>
                                {
                                    studentd?
                                        <label className="fw-bold text-dark col-md-4 mt-2">{studentd.student_aadhar}</label>
                                        :
                                        <label></label>
                                }
                                <label className="col-md-12 mt-2" htmlFor="name">Address:</label>
                                {
                                    studentd?
                                        <label className="fw-bold text-dark col-md-12 mt-2">{studentd.student_address}</label>
                                        :
                                        <label></label>
                                }

                            </div>

                            {/*<div className="bg-secondary text-light fw-bold p-2">*/}
                            {/*    <label >*/}
                            {/*        Convenience Fee Details*/}
                            {/*    </label>*/}
                            {/*</div>*/}
                            {/*/!**!/*/}
                            {/*<table className="table table-hover table-bordered">*/}
                            {/*    <tr>*/}
                            {/*        /!*<th>S.No.</th>*!/*/}
                            {/*        <th>Transaction Details</th>*/}
                            {/*        <th>College Name</th>*/}
                            {/*        <th>Amount Paid</th>*/}
                            {/*    </tr>*/}



                            {/*{*/}
                            {/*    convFee.map(item=>{*/}
                            {/*        return( <tr>*/}

                            {/*            <td>conv_{item.conveniencefeedetail_id}</td>*/}
                            {/*            <td>{item.college_name}</td>*/}
                            {/*            <td>{item.amount_paid}</td>*/}
                            {/*        </tr>)*/}
                            {/*    })*/}
                            {/*}*/}
                            {/*</table>*/}
                            <div className="bg-secondary text-light fw-bold p-2">
                                <label >
                                    Prospectus Fee Details
                                </label>
                            </div>
                            {/**/}
                            <table className="table table-hover table-bordered">
                                <tr>
                                    {/*<th>S.No.</th>*/}
                                    <th>Transaction Details</th>
                                    <th>College Name</th>
                                    <th>Stream Name</th>
                                    <th>Amount Paid</th>
                                </tr>
                                {
                                    regFee.map(item=>{
                                        ReactSession.set("stream_id",item.stream_id);
                                        ReactSession.set("college_id",item.college_id);
                                        ReactSession.set("amount",item.paid_amount);
                                        return( <tr>

                                            <td>reg_{item.paymentdetail_id}</td>
                                            <td>{item.college_name}</td>
                                            <td>{item.stream_name}</td>
                                            <td>{item.paid_amount}</td>
                                        </tr>)
                                    })
                                }
                            </table>
                            <div className="m-3 text-justify">
                                NOTE:- PLEASE PRESS CONFIRM BUTTON TO SUBMIT YOUR FORM. ANY CHANGES, IF REQUIRED, WILL BE CONSIDER DURING THE ADMISSION PROCESS.<br/>
                                <strong>TO GENERATE APPLICATION ID</strong><br/>
                                1. YOU WILL BE REDIRECTED TO DASHBOARD. <br/>
                                2. CLICK ON "GENERATE APPLICATION ID" BUTTON TO GENERATE THE APPLICATION ID.<br/>
                                3. AFTER SUCCESSFUL PROCESS OF GENERATE THE APPLICATION ID YOU WILL GET A BUTTON NAMED "APPLICATION".<br/>
                                4. CLICK ON APPLICATION BUTTON TO GET A PRINT OF DESIRED APPLIED COURSE AND COLLEGE.<br/>
                                        <button className="btn btn-primary btn-sm" onClick={setConfirm}>Confirm</button>

                            {/*<button className="btn btn-primary btn-sm" onClick={setConfirm}>Confirm</button>*/}
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConfirmStudentDetail;