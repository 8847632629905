import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Axios from "axios";
import {ReactSession} from "react-client-session";
import ArrowHeader from "./arrowHeader";
import {IsValidLogin} from "./isLoggedIn";
// import {useHistory} from "react-router";
// import {withRouter} from "react-router-dom";

const StudentDetail=props=>{
    const history=useHistory();
    ReactSession.setStoreType("localStorage")
    useEffect(()=>{
        if(!IsValidLogin()){
            // console.log("entered");
            localStorage.clear();
            history.push("/");
        }
        alert("Please note Only newly entered information will get affect.")
        getStudentBasic();
        getCategories();
    },[])
    const [student,setStudent]=useState({
        student_name:"",student_fathername:"",student_dob:"",student_gender:"",
        student_mobile:"",student_email:"",student_batch:"",student_id:0
    });

    const[studentd,setStudentd]=useState({
        student_mothername:"", student_caste:"",student_address:"",student_aadhar:"",
        student_pwd:"",student_nationality:"",student_religion:"",belongto_jac:"",weight:"",
        height:"",bloodgroup:"",
    })
    //let errorsObj={stduent_email:'',student_password:''};
    //const [errors,setErrors]=useState(errorsObj);

    let eleName,value;
    const handleInputs=(e)=>{
        eleName=e.target.name;
        value=e.target.value.toUpperCase();
        setStudentd({...studentd,[eleName]:value});
        console.log(studentd);
    }

    const[categories,setCategories]=useState([{}]);
    const getCategories=async ()=>{
        const allCastes=await Axios.get(`${process.env.REACT_APP_URI}caste/category`).then((res)=>{
            setCategories(res.data);
        }).catch(err=>{
            console.log(err.message);
        });
        console.log(categories);
    }
    const getStudentBasic=async ()=>{
        const student_id=ReactSession.get("student_id");
        const student1=await Axios.get(`${process.env.REACT_APP_URI}student/byid/${student_id}`).then((res)=>{
            setStudent(res.data);
            console.log(res.data);
        });
        console.log(student);
    }

    const getStudentDetail=()=>{

    }

    const postStudentDetail=async (e)=>{
        var response={};
        e.preventDefault();
        console.log(studentd)
        const {student_name,student_fatherName,student_dob,student_gender,
            student_mobile,student_email,student_batch}=student;
        const res=await Axios.post(`${process.env.REACT_APP_URI}student/detail`,{
            student_id:ReactSession.get("student_id"),
            student_mothername :studentd.student_mothername,
            student_caste :studentd.student_caste,
            student_address :studentd.student_address,
            student_aadhar:studentd.student_aadhar,
            student_pwd:studentd.student_pwd,
            student_nationality:studentd.student_nationality,
            student_religion:studentd.student_religion,
            belongto_jac:studentd.belongto_jac,
            height:studentd.height,
            weight:studentd.weight,
            bloodgroup:studentd.bloodgroup,
        },{

            headers:{
                "Content-Type":"application/json",
            },
        }).then((res)=>
        {
            response=res.data;
            console.log(res);
            ReactSession.setStoreType("localStorage");
            ReactSession.set("session_status","Active");
            ReactSession.set("session_role","Student");
            ReactSession.set("student_email",student.student_email);
        });
        console.log(response);
        if(!response || response.status>=400){
            console.log(response);
            alert("Please check the form again");
        }else{
            if(response.isUpdated) {
                console.log(response)
                alert("Information updated Successfully");
            }
            else{
                alert('Last saved information retained1');
                return;
            }
            history.push("/student/previous/academic");
        }

    }
    return(
        <>
            <ArrowHeader form="detail"  />
            <div className="container">
                <div className="row">
                    <div className="col-md-3">&nbsp;</div>
                    <div className="col-md-6">
                        <div className="card border-secondary mt-3 text-justify">
                            <div className="card-header"><h4 className="card-title"> Student's Personal Details</h4></div>
                            <div className="card-body">
                                <div className="container form-floating">
                                    <form method="POST" id="form-studentdetail" onSubmit={postStudentDetail}>
                                        <div className="row">
                                            <div className="col-md-6 ">
                                                <label>Student Name</label>
                                                <input type="text" name="student_name" className="form-control"
                                                       value={student.student_name} onChange={handleInputs} readOnly
                                                />
                                            </div>
                                            <div className="col-md-6 ">
                                                <label >Father's Name</label>
                                                <input type="text" name="student_fatherName" className="form-control"
                                                       value={student.student_fatherName} onChange={handleInputs} readOnly={true}
                                                />
                                            </div>
                                            <div className="col-md-6 ">
                                                <label className="mt-3">Mother's Name</label>
                                                <input type="text" name="student_mothername" className="form-control"
                                                       value={studentd.student_mothername} onChange={handleInputs}
                                                       pattern="[a-zA-Z'-'\s]*" required={true}
                                                />
                                            </div>
                                            {/*get the list of caste*/}
                                            <div className="col-md-6 ">
                                                <label className="mt-3">Caste Category</label>
                                                <select name="student_caste" className="form-control"
                                                       value={studentd.student_caste} onChange={handleInputs}
                                                        required
                                                >
                                                    <option value="">--select category--</option>
                                                    {
                                                        categories.map(item=>{
                                                            return <option key={item.category_id} value={item.category_id}>{item.category_name}</option>
                                                            // return <option key={item.category_id} value={item.category_id}>{item.category_name}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-md-6 ">
                                                <label className="mt-3">Caste Certificate issued by Jharkhand</label>
                                                <select name="belongto_jac" className="form-control"
                                                        value={studentd.belongto_jac} onChange={handleInputs}
                                                        required
                                                >
                                                    <option value={''}>--select --</option>
                                                    <option value={'YES'}>YES</option>
                                                    <option value={'NO'}>NO</option>

                                                </select>
                                            </div>
                                            <div className="col-md-6 ">
                                            <label className="mt-3">Physical Handicap</label>
                                            <select name="student_pwd" className="form-control"
                                                    value={studentd.student_pwd} onChange={handleInputs}
                                                    required
                                            >
                                                <option value={''}>--select --</option>
                                                <option value={'NONE'}>NONE</option>
                                                <option value={'BLIND'}>BLIND</option>
                                                <option value={'DEAF'}>DEAF</option>
                                                <option value={'DUMB'}>DUMB</option>
                                                <option value={'SPASTIC'}>SPASTIC</option>
                                                <option value={'ORTHOPEDIC'}>ORTHOPEDIC</option>
                                            </select>
                                        </div>

                                            <div className="col-md-6 ">
                                                <label className="mt-3">Religion</label>
                                                <select name="student_religion" className="form-control"
                                                        value={studentd.student_religion} onChange={handleInputs}
                                                        required
                                                >
                                                    <option value="">--select religion--</option>
                                                    <option value={'HINDUISM'}>HINDUISM</option>
                                                    <option value={'ISLAM'}>ISLAM</option>
                                                    <option value={'SIKHISM'}>SIKHISM</option>
                                                    <option value={'CHRISTIANITY'}>CHRISTIANITY</option>
                                                    <option value={'OTHERS'}>OTHERS</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 ">
                                                <label className="mt-3">Nationality</label>
                                                <select name="student_nationality" className="form-control"
                                                        value={studentd.student_nationality} onChange={handleInputs}
                                                        required
                                                >
                                                    <option value="">--select nationality--</option>
                                                    <option value={'INDIAN'}>INDIAN</option>
                                                    <option value={'OTHERS'}>OTHERS</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 ">
                                                <label className="mt-3">Aadhar Number</label>
                                                <input type="text" name="student_aadhar" className="form-control"
                                                       value={studentd.student_aadhar} onChange={handleInputs}
                                                       minLength={12} maxLength={12}
                                                       required
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="mt-3">Address</label>
                                                <textarea  name="student_address" className="form-control"
                                                       value={studentd.student_address} onChange={handleInputs}
                                                           required
                                                ></textarea>
                                            </div>

                                            {/*<div className="col-md-6 ">*/}
                                            {/*    <label className="mt-3">Date of Birth</label>*/}
                                            {/*    <input type="date" name="student_dob" className="form-control"*/}
                                            {/*           value={student.student_dob} onChange={handleInputs}*/}
                                            {/*    />*/}
                                            {/*</div>*/}
                                            <div className="col-md-6 ">
                                                <label className="mt-3">Gender</label>
                                                {/*<select name="student_gender" className="form-control"*/}
                                                {/*        value={student.student_gender} onChange={handleInputs}*/}

                                                {/*>*/}
                                                {/*    <option value="">--select gender--</option>*/}
                                                {/*    <option value="MALE">MALE</option>*/}
                                                {/*    <option value="FEMALE">FEMALE</option>*/}
                                                {/*    <option value="TRANSGENDER">TRANSGENDER</option>*/}
                                                {/*</select>*/}
                                                <input type={"text"} name="student_gender" className="form-control"
                                                        value={student.student_gender}

                                                />

                                            </div>
                                            <div className="col-md-6 ">
                                                <label className="mt-3">Mobile No.</label>
                                                <input type="text" name="student_mobile" className="form-control"
                                                       value={student.student_mobile} onChange={handleInputs} readOnly={true}
                                                />
                                            </div>

                                            <div className="col-md-6 ">
                                                <label className="mt-3">Email ID</label>
                                                <input type="email" name="student_email" className="form-control"
                                                       value={student.student_email} onChange={handleInputs} readOnly={true}
                                                />
                                            </div>

                                            <div className="col-md-6 ">
                                                <label className="mt-3">Height (in cms)</label>
                                                <input type="text" name="height" className="form-control"
                                                       value={student.height} onChange={handleInputs}
                                                />
                                            </div>
                                            <div className="col-md-6 ">
                                                <label className="mt-3">Weight (in kg)</label>
                                                <input type="text" name="weight" className="form-control"
                                                       value={student.weight} onChange={handleInputs}
                                                />
                                            </div>
                                            <div className="col-md-6 ">
                                                <label className="mt-3">Blood Group</label>
                                                <input type="text" name="bloodgroup" className="form-control"
                                                       value={student.bloodgroup} onChange={handleInputs}
                                                />
                                            </div>


                                            <button  className="btn btn-sm btn-success mt-3 mx-2" ><i className="fa fa-save"></i> Submit Information</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">&nbsp;</div>
                </div>
            </div>
        </>
    )
}

export default StudentDetail;