import React from 'react';

const UploadCollegeStudentBulk=()=>{
    const uploadFile=(e)=>{
        var filename=e.target.files[0];

    }
    return(
        <>
            <form encType="multipart/form-data" method="post" style={{"margin-top":"20px"}}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-md-4'}>

                        </div>
                        <div className={'col-md-4'}>
                            <input type="file" className={'form-control'} name="uploadfile" accept='csv'/>
                            <input type="submit" className={'form-control mt-2'} value="Upload Student CSV"/>
                        </div>
                        <div className={'col-md-4'}>

                        </div>
                    </div>
                </div>

            </form>
        </>
    )
}

export default UploadCollegeStudentBulk;