import React, {useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {ReactSession} from "react-client-session";
import {useEffect} from "react";
import Axios from "axios";

const getAdmissionDetails=()=>{
    const history=useHistory();
    ReactSession.setStoreType("localStorage");
    useEffect(()=>{
        const Role=ReactSession.get("role");
        if(Role!=="collegeadmin" && Role!=="mainadmin")
        {
            alert("Please login first");
            history.push('/admin')
        }
        getAllGenderReport();
    },[])
    const [details,setDetails]=useState();
    const getAllGenderReport=async ()=>{
        const uri=`${process.env.REACT_APP_URI}student/get/admitted/all/categories`;
        // alert(uri)
        const data=await Axios.get(uri).then((res)=>{
            console.log(res.data);
            setDetails(res.data)
        }).catch(err=>{
            console.log(err.message)
        })
    }

    function printDiv(divName) {
        var printContents = document.getElementById(divName).innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }


    return(
        <div id="printableArea">
            <div className='container'>
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <legend>Admission Details for All Categories</legend>
                        <table className="table table-hover table-bordered">
                            <tr>
                                <th colSpan={7}>
                                    Gender: ALL
                                </th>
                            </tr>
                            <tr>
                                <th>Category</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>EWS</th>
                                <th>OBC-I</th>
                                <th>OBC-II</th>
                                <th>UR</th>
                                <th>All</th>
                            </tr>
                            {
                                details?
                                    <>
                                        <tr>
                                            <th>ARTS</th>
                                            <td>{details.scArts}</td>
                                            <td>{details.stArts}</td>
                                            <td>{details.ewsArts}</td>
                                            <td>{details.obc1Arts}</td>
                                            <td>{details.obc2Arts}</td>
                                            <td>{details.urArts}</td>
                                            <td>{details.AllArts}</td>
                                        </tr>
                                        <tr>
                                            <th>PCM</th>
                                            <td>{details.scPCM}</td>
                                            <td>{details.stPCM}</td>
                                            <td>{details.ewsPCM}</td>
                                            <td>{details.obc1PCM}</td>
                                            <td>{details.obc2PCM}</td>
                                            <td>{details.urPCM}</td>
                                            <td>{details.AllPCM}</td>
                                        </tr>
                                        <tr>
                                            <th>PCB</th>
                                            <td>{details.scPCB}</td>
                                            <td>{details.stPCB}</td>
                                            <td>{details.ewsPCB}</td>
                                            <td>{details.obc1PCB}</td>
                                            <td>{details.obc2PCB}</td>
                                            <td>{details.urPCB}</td>
                                            <td>{details.AllPCB}</td>
                                        </tr>
                                        <tr>
                                            <th>Comm</th>
                                            <td>{details.scComm}</td>
                                            <td>{details.stComm}</td>
                                            <td>{details.ewsComm}</td>
                                            <td>{details.obc1Comm}</td>
                                            <td>{details.obc2Comm}</td>
                                            <td>{details.urComm}</td>
                                            <td>{details.AllComm}</td>
                                        </tr>
                                    </>
                                    :
                                    "No Record Found"
                            }
                        </table>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>

            <div className='container'>
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <legend>Admission Details for Females Only </legend>
                        <table className="table table-hover table-bordered">
                            <tr>
                                <th colSpan={7}>
                                    Gender: Female
                                </th>
                            </tr>
                            <tr>
                                <th>Category</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>EWS</th>
                                <th>OBC-I</th>
                                <th>OBC-II</th>
                                <th>UR</th>
                                <th>All</th>
                            </tr>
                            {
                                details?
                                    <>
                                        <tr>
                                            <th>ARTS</th>
                                            <td>{details.scFemaleArts}</td>
                                            <td>{details.stFemaleArts}</td>
                                            <td>{details.ewsFemaleArts}</td>
                                            <td>{details.obc1FemaleArts}</td>
                                            <td>{details.obc2FemaleArts}</td>
                                            <td>{details.urFemaleArts}</td>
                                            <td>{details.FemaleArts}</td>
                                        </tr>
                                        <tr>
                                            <th>PCM</th>
                                            <td>{details.scFemalePCM}</td>
                                            <td>{details.stFemalePCM}</td>
                                            <td>{details.ewsFemalePCM}</td>
                                            <td>{details.obc1FemalePCM}</td>
                                            <td>{details.obc2FemalePCM}</td>
                                            <td>{details.urFemalePCM}</td>
                                            <td>{details.FemalePCM}</td>
                                        </tr>
                                        <tr>
                                            <th>PCB</th>
                                            <td>{details.scFemalePCB}</td>
                                            <td>{details.stFemalePCB}</td>
                                            <td>{details.ewsFemalePCB}</td>
                                            <td>{details.obc1FemalePCB}</td>
                                            <td>{details.obc2FemalePCB}</td>
                                            <td>{details.urFemalePCB}</td>
                                            <td>{details.FemalePCB}</td>
                                        </tr>
                                        <tr>
                                            <th>Comm</th>
                                            <td>{details.scFemaleComm}</td>
                                            <td>{details.stFemaleComm}</td>
                                            <td>{details.ewsFemaleComm}</td>
                                            <td>{details.obc1FemaleComm}</td>
                                            <td>{details.obc2FemaleComm}</td>
                                            <td>{details.urFemaleComm}</td>
                                            <td>{details.FemaleComm}</td>
                                        </tr>
                                    </>
                                    :
                                    "No Record Found"
                            }
                        </table>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>


            <div className='container'>
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <legend>Admission Details for Males Only </legend>
                        <table className="table table-hover table-bordered">
                            <tr>
                                <th colSpan={7}>
                                    Gender: Male
                                </th>
                            </tr>
                            <tr>
                                <th>Category</th>
                                <th>SC</th>
                                <th>ST</th>
                                <th>EWS</th>
                                <th>OBC-I</th>
                                <th>OBC-II</th>
                                <th>UR</th>
                                <th>All</th>
                            </tr>
                            {
                                details?
                                    <>
                                        <tr>
                                            <th>ARTS</th>
                                            <td>{details.scMaleArts}</td>
                                            <td>{details.stMaleArts}</td>
                                            <td>{details.ewsMaleArts}</td>
                                            <td>{details.obc1MaleArts}</td>
                                            <td>{details.obc2MaleArts}</td>
                                            <td>{details.urMaleArts}</td>
                                            <td>{details.MaleArts}</td>
                                        </tr>
                                        <tr>
                                            <th>PCM</th>
                                            <td>{details.scMalePCM}</td>
                                            <td>{details.stMalePCM}</td>
                                            <td>{details.ewsMalePCM}</td>
                                            <td>{details.obc1MalePCM}</td>
                                            <td>{details.obc2MalePCM}</td>
                                            <td>{details.urMalePCM}</td>
                                            <td>{details.MalePCM}</td>
                                        </tr>
                                        <tr>
                                            <th>PCB</th>
                                            <td>{details.scMalePCB}</td>
                                            <td>{details.stMalePCB}</td>
                                            <td>{details.ewsMalePCB}</td>
                                            <td>{details.obc1MalePCB}</td>
                                            <td>{details.obc2MalePCB}</td>
                                            <td>{details.urMalePCB}</td>
                                            <td>{details.MalePCB}</td>
                                        </tr>
                                        <tr>
                                            <th>Comm</th>
                                            <td>{details.scMaleComm}</td>
                                            <td>{details.stMaleComm}</td>
                                            <td>{details.ewsMaleComm}</td>
                                            <td>{details.obc1MaleComm}</td>
                                            <td>{details.obc2MaleComm}</td>
                                            <td>{details.urMaleComm}</td>
                                            <td>{details.MaleComm}</td>
                                        </tr>
                                    </>
                                    :
                                    "No Record Found"
                            }
                        </table>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
            <div className={'text-center'}>
                <Link to={'/admin/dashboard'} className="btn btn-warning btn-sm mx-2">Home</Link>
                <button type={"button"} className="btn btn-primary btn-sm" onClick={()=>printDiv('printableArea')} ><i className="fa fa-print"></i>Print</button>
            </div>
        </div>

    )
}

export default getAdmissionDetails;