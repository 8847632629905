import React, {useEffect, useState} from 'react';
import Axios from 'axios';
import {ReactSession} from "react-client-session";
import dotenv from "dotenv";
import dateFormat from "dateformat";
import {useHistory} from "react-router-dom";
import {IsValidLogin} from "../isLoggedIn";
import apply from "../apply/apply";
dotenv.config();

const PrintAdmission=(props)=>{
    const history=useHistory();
    ReactSession.setStoreType("localStorage");
    const [studentDetail,setStudentDetail]=useState({});
    const [studentb,setStudentB]=useState({});
    const [studentd,setStudentD]=useState({});
    const [convFee,setConvFee]=useState([{}]);
    const [regFee,setRegFee]=useState([{}]);
    const [admFee,setAdmFee]=useState([{}]);
    const [streamid,setStreamID]=useState(0);
    const [college_id,setCollege_ID]=useState(0);
    const [appid,setAppID]=useState('');
    useEffect(()=>{
        if(!IsValidLogin()){
            // console.log("entered");
            localStorage.clear();
            history.push("/");
        }
        // alert(props.match.params.strid);
        // alert(props.match.params.college_id)
        setStreamID(props.match.params.strid);
        setCollege_ID(props.match.params.college_id)
        getStudentDetail();
        setStudentB(studentDetail.studentb);
        setStudentD(studentDetail.studentd);
        getRegid();
        getAcademicDetails();
        getSubjectDetails();
        alert(`${process.env.REACT_APP_URI}`)

    },[]);
    const getAllDetails=()=>{
        console.log(studentb)
    }

    const logout=()=>{
        localStorage.clear();
        history.push("/");
    }

    const [subjectDetails,setSubjectDetails]=useState({
        core1:"", core2:'', optional1:'',stream:'',group:'',
    })
    const getSubjectDetails=async ()=>{
        const uri=`${process.env.REACT_APP_URI}student/selected/subject/${ReactSession.get("student_id")}/${props.match.params.strid}`;
        // alert(uri)
        const data=await Axios.get(uri).then((res)=>{
            // console.log(res.data);
            setSubjectDetails(res.data)
        }).catch(err=>{
            console.log(err.message)
        })
    }
    const [college,setCollege]=useState('');
    const [doc,setDoc]=useState([{}])
    const getStudentDetail=async ()=>{
        // console.log(props);
        const uri=`${process.env.REACT_APP_URI}student/confirmed/detail/${ReactSession.get("student_id")}/${props.match.params.college_id}/${props.match.params.strid}`;
        // alert(uri)
        const docs=await Axios.get(`${process.env.REACT_APP_URI}student/doc/detail/${ReactSession.get("student_id")}`);
        console.log("Docs",docs)
        setDoc(docs.data[0])
        const studentDetails=await Axios.get(`${uri}`)
            .then((res)=>{
                console.log(res.data);
                var {studentb,studentd,conFee,admFee}=res.data;
                ReactSession.set("Category",res.data.admFee[0].category_name);
                setStudentDetail(res.data);
                setConvFee(conFee);
                setStudentB(studentb);
                setStudentD(studentd);
                setAdmFee(admFee);
                getAcademicDetails();

            }).catch(err=>{
                console.log(err);
            })
    }
    const [aggregateSubjectDetails,setAggregateSubjectDetails]=useState({});

    const [acadDetails,setAcadDetails]=useState({})
    const [completeSubjectsDetails,setCompleteSubjectsDetails]=useState([{}])
    const getAcademicDetails=async ()=>{
        const acadDetails1=await Axios.get(`${process.env.REACT_APP_URI}student/academic/marks/detail/${ReactSession.get("student_id")}`)
            .then((res)=>{
                // console.log(res.data)
                ReactSession.set("Previous_school",res.data.subjectDetails[0].school_name)
                ReactSession.set("Passing_Year",res.data.subjectDetails[0].pass_year)
                ReactSession.set("board_name",res.data.subjectDetails[0].board_name)
                setAcadDetails(res.data.subjectDetails);
                setCompleteSubjectsDetails(res.data.completeSubjectDetails)
            }).catch(err=>{
                console.log(err.message)
            })

    }

    const getRegid=async ()=>{
        var currentYear =  (new Date()).getFullYear();
        var twoLastDigits = currentYear%100;
        var formatedTwoLastDigits = "";

        if (twoLastDigits <10 ) {
            formatedTwoLastDigits = "0" + twoLastDigits;
        } else {
            formatedTwoLastDigits = "" + twoLastDigits;
        }
        var formatedStudentId = "";
        if(ReactSession.get("student_id")<10){
            formatedStudentId="000"+ReactSession.get("student_id")
        }
        else if(ReactSession.get("student_id")<100){
            formatedStudentId="00"+ReactSession.get("student_id")
        }else if(ReactSession.get("student_id")<1000){
            formatedStudentId="0"+ReactSession.get("student_id")
        }
        else{
            formatedStudentId=ReactSession.get("student_id");
        }
        if(ReactSession.get("college_code")!==undefined && ReactSession.get("stream_id")!==undefined) {
            var appid= formatedTwoLastDigits.toString()+ReactSession.get("college_code")+ReactSession.get("stream_id")+formatedStudentId.toString();
            // console.log(appid)
            setAppID(appid);
            ReactSession.set("AppCode",appid)
        }
        console.log("Applied Application number:",appid);
        await Axios.put(`${process.env.REACT_APP_URI}student/stream/app/${ReactSession.get("student_id")}/${ReactSession.get("stream_id")}/${ReactSession.get("college_id")}`,{
            application_no:appid,
        }).then((res)=>{
            console.log("Application ID Updated Successfully")
        })
    }



    function printDiv(divName) {
        var printContents = document.getElementById(divName).innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }
    const myImage={
        border:"1px solid #999",
        minHeight:"160px",
        maxHeight:"160px",
        minWidth:"130px",
        maxWidth:"130px",
        marginTop:"10px",
        marginBottom:"10px"
    }

    const mySign={
        border:"1px solid #999",
        minHeight:"80px",
        maxHeight:"80px",
        minWidth:"180px",
        maxWidth:"180px",
        marginTop:"10px",
        marginBottom:"10px"
    }
    return(
        <>
            <a className="btn btn-sm btn-danger mt-4 mx-5" onClick={logout}>Logout</a>
            <div className="container mt-4" id="printableArea">
                <div className="row p-2">
                    <div className="col-md-12">
                        <fieldset className="border">
                            {
                                studentb?
                                    <></>:
                                    <h1>Please wait.. we're fetching the data</h1>
                            }

                            <table className="table table-bordered">
                                <thead>
                                <tr className="bg-secondary text-light fw-bold p-2">
                                    <th colSpan={4} ><legend className="fw-bold text-uppercase" > A S College Deoghar <span className={"fs-5"}>(Session 2022-2024)</span></legend>
                                    </th>
                                </tr>
                                <tr>
                                    <td colSpan={4}>
                                        <table className="table table-bordered">
                                            <tr>
                                                {/*<td><legend>Application No. : {ReactSession.get("Application_ID")}</legend></td>*/}
                                                <td><legend>Admission Course :</legend> {subjectDetails.stream_name}:-{subjectDetails.core1},
                                                    {subjectDetails.core2}, {subjectDetails.optional1},
                                                    {subjectDetails.group}</td>
                                                <td><img src={`${process.env.REACT_APP_URI}images/photo/${doc.photo}`} style={myImage}/><img src={`${process.env.REACT_APP_URI}images/sign/${doc.sign}`} style={mySign}/>  </td>
                                            </tr>
                                        </table>
                                    </td>

                                </tr>
                                <tr>
                                    <td width="20%">Student Name:</td>
                                    <td colSpan={3}>
                                        {
                                            studentb?
                                                <label className="fw-bold text-dark ">{studentb.student_name}</label>
                                                :
                                                <label></label>
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td width="20%">Father's Name:</td>
                                    <td width="30%">
                                        {
                                            studentb?
                                                <label className="fw-bold text-dark col-md-9 ">{studentb.student_fatherName}</label>
                                                :
                                                <label></label>
                                        }
                                    </td>
                                    <td width="20%">Mother's Name:</td>
                                    <td width="30%">
                                        {
                                            studentd?
                                                <label className="fw-bold text-dark col-md-9 ">{studentd.student_mothername}</label>
                                                :
                                                <label></label>
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td width="20%">Gender:</td>
                                    <td>
                                        {
                                            studentb?
                                                <label className="fw-bold text-dark col-md-9 ">{studentb.student_gender}</label>
                                                :
                                                <label></label>
                                        }
                                    </td>
                                    <td width="20%">Date of Birth:</td>
                                    <td>
                                        {
                                            studentb?
                                                <label className="fw-bold text-dark col-md-9 ">{dateFormat(studentb.student_dob,"dd/mmm/yyyy")}</label>
                                                :
                                                <label></label>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td width="20%">Email ID:</td>
                                    <td>
                                        {
                                            studentb?
                                                <label className="fw-bold text-dark col-md-9 ">{studentb.student_email}</label>
                                                :
                                                <label></label>
                                        }
                                    </td>
                                    <td width="20%">Mobile No.:</td>
                                    <td>
                                        {
                                            studentb?
                                                <label className="fw-bold text-dark col-md-9 ">{studentb.student_mobile}</label>
                                                :
                                                <label></label>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td >Address:</td>
                                    <td colSpan={3}>
                                        {
                                            studentd?
                                                <label className="fw-bold text-dark col-md-9 ">{studentd.student_address}</label>
                                                :
                                                <label></label>
                                        }
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={4}>
                                        <table className="table table-bordered">
                                            <tr>
                                                <td width={"20%"} >Last Attended School:</td>
                                                <td colSpan={3}>
                                                    {
                                                        // console.log(acadDetails)
                                                        ReactSession.get("Previous_school")!==undefined?
                                                            <label className="fw-bold text-dark col-md-9 ">{ReactSession.get("Previous_school")}</label>
                                                            :
                                                            <label>no record</label>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="16%">Passed in:</td>
                                                <td width="17%">
                                                    {
                                                        // // console.log(acadDetails)
                                                        ReactSession.get("Passing_Year")!==undefined?
                                                            <label className="fw-bold text-dark col-md-9 ">{ReactSession.get("Passing_Year")}</label>
                                                            :
                                                            <label>no record</label>
                                                    }
                                                </td>
                                                <td width="16%">Aadhar No.:</td>
                                                <td width="17%">
                                                    {
                                                        studentd?
                                                            <label className="fw-bold text-dark col-md-9 ">{studentd.student_aadhar}</label>
                                                            :
                                                            <label></label>
                                                    }
                                                </td>
                                            </tr>
                                        </table>
                                    </td>

                                </tr>

                                <tr >
                                    <td colSpan={4}>
                                        <table className="table table-bordered">
                                            <tr>
                                                <td>Board Name:</td>
                                                <td>
                                                    {
                                                        // console.log(acadDetails)
                                                        ReactSession.get("board_name")!==undefined?
                                                            <label className="fw-bold text-dark col-md-9 ">{ReactSession.get("board_name")}</label>
                                                            :
                                                            <label>no record</label>
                                                    }
                                                </td>
                                                <td width="20%">Category:</td>
                                                <td>
                                                    {
                                                        admFee?
                                                            <label className="fw-bold text-dark col-md-9 ">{admFee[0].category_name}</label>
                                                            :
                                                            <label></label>
                                                    }
                                                </td>
                                                <td width="20%">Physical Handicap:</td>
                                                <td>
                                                    {
                                                        studentd?
                                                            <label className="fw-bold text-dark col-md-9 ">{studentd.student_pwd}</label>
                                                            :
                                                            <label></label>
                                                    }
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>

                                </thead>

                            </table>
                            {
                                completeSubjectsDetails.length>4?
                                    <>
                                        <div className="bg-secondary text-light fw-bold p-2">
                                            <label >
                                                Subject details
                                            </label>
                                        </div>
                                        {/**/}
                                        <table className="table table-hover table-bordered">
                                            <tr>
                                                {
                                                    completeSubjectsDetails.map(item=>{
                                                        return(
                                                            <th width="20%">{item.subject_name}<br/>{item.subject_marksobtained}</th>
                                                        )
                                                    })
                                                }
                                            </tr>
                                            <tr>
                                                <td colSpan={5}>
                                                    <table className="table table-bordered">
                                                        <tr>
                                                            {
                                                                acadDetails.map(item=>{
                                                                    return(
                                                                        <>
                                                                            <th width="20vh">Marks Obtained</th>
                                                                            <td  width="20vh">{parseFloat(item.average_marks).toFixed(2)}</td>
                                                                            <th  width="35vh">Marks Obtained/Max. Marks</th>
                                                                            <td width="25vh">{item.total_marks}/{item.total_maxmarks}</td>
                                                                        </>
                                                                    )
                                                                })

                                                            }
                                                        </tr>
                                                    </table>

                                                </td>
                                            </tr>
                                        </table>
                                    </>
                                    :
                                    <div>
                                        Please contact to Administrator
                                    </div>
                            }



                            {
                                // convFee.length>0?
                                <>
                                    <div className="bg-secondary text-light fw-bold p-2">
                                        <label >
                                            Admission Fee Details
                                        </label>
                                    </div>
                                    {/**/}
                                    <table className="table table-hover table-bordered">
                                        <tr>
                                            {/*<th>S.No.</th>*/}
                                            <th>Transaction ID</th>
                                            <th>Stream Name</th>
                                            <th>Amount </th>
                                            <th>Transaction Date</th>
                                        </tr>
                                        {

                                            admFee.map(item=>{
                                                console.log("item",item)
                                                ReactSession.set("stream_id",item.stream_id);
                                                ReactSession.set("college_id",item.college_id);
                                                ReactSession.set("college_name",item.college_name);
                                                ReactSession.set("college_code",item.college_code);
                                                ReactSession.set("amount",item.paid_amount);
                                                ReactSession.set("stream_name",item.stream_name);
                                                return( <tr>

                                                    <td>adm_{item.feedetail_id}</td>

                                                    <td>{item.stream_name}</td>
                                                    <td>{item.paid_amount}</td>
                                                    <td>{dateFormat(item.createdAt,"dd/mm/yyyy HH:MM:ss")}</td>
                                                </tr>)
                                            })
                                        }
                                    </table>


                                    <small>
                                        Note: It is provisional admission subject to verification of your documents and fee paid.The admission will be cancelled if any information is found to be wrong.
                                    </small>
                                </>
                            }


                        </fieldset>
                    </div>
                </div>
            </div>
            <div className="m-3 text-center">

                <button type={"button"} className="btn btn-primary btn-sm" onClick={()=>printDiv('printableArea')} ><i className="fa fa-print"></i>Print Application Form</button>
                {/*<a className="btn btn-sm btn-danger" onClick={logout}> <i className="fa fa-sign-out"></i> Logout</a>*/}
            </div>
        </>
    )

}

export default PrintAdmission;