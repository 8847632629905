import React, {useEffect, useState} from "react";
import {ReactSession} from 'react-client-session';
// import {useHistory} from "react-router";
import Axios from "axios";
import {useHistory} from "react-router-dom";
import ArrowHeader from "../arrowHeader";
// import {withRouter} from "react-router-dom";


const AcademicDetails=props=>{
    const [elementItem,setElementItem]=useState(0);
    ReactSession.setStoreType("localStorage");
    const [formValues,setFormValues]=useState([{
        school_id:0,standard_name:"",subject_name:"",
        subject_maxmarks:0,subject_marksobtained:0,subject_type:0
    }])
    const [academic,setAcademic]=useState([]);
    const [academicDetails,setAcademicDetails]=useState({
        student_id:0,school_id:0,standard_name:"",subject_name:"",
        subject_maxmarks:0,subject_marksobtained:0,subject_type:0
    })

    const getAcademicDetails=async ()=>{
        const academicDetails=await Axios.get(`${process.env.REACT_APP_URI}student/academic/marks/byid/${ReactSession.get("student_id")}`).then((res)=>{
            if(res.data.length>0){
                history.push('/student/applied/stream');
            }
        })
    }

    useEffect(()=>{
        getSchools();
        getSubjectType();
        getAcademicDetails();
    },[])
    const history=useHistory();
    let eleName,value;
    const handleInputs=(i,e)=>{
        let newAcademicDetails=[...formValues];
        newAcademicDetails[i][e.target.name]=e.target.value;
        setFormValues(newAcademicDetails);
    }

    const handleInputs1=(i,e)=>{
        let newAcademicDetails=[...formValues];
        newAcademicDetails[i][e.target.name]=e.target.value.toUpperCase();
        setFormValues(newAcademicDetails);
    }


    const [schoolDetail,setSchoolDetail]=useState({
        school_name:"",
        board_name:"",
        pass_year:0,
        school_exammode:"",
        school_boardrollno:"",
        school_rollcode:"",
        school_registrationno:"",
        school_religion:"",
    });
    const handleInput=(e)=>{
        eleName=e.target.name;
        value=e.target.value.toUpperCase();
        setSchoolDetail(({...schoolDetail,[eleName]:value}))
    }
    const [school,setSchool]=useState(0);
    const handleSelect=(e)=>{
        //eleName="school_id";
        console.log(e.target.value);
        value=e.target.value;
        setSchool(e.target.value);
        if(e.target.value==-1){
            // alert("fill the new school detail");
            history.push("/student/previous/school")
        }else{
            ReactSession.set("prevschool_id",e.target.value);
        }

    }
    const [isAcademicCompleted,setIsAcademicCompleted]=useState(false);
    const getAcademicData=async ()=>{
        try {
            console.log(`${process.env.REACT_APP_URI}student/detail/all/${ReactSession.get("student_id")}`)
            const getProfile = await Axios.get(`${process.env.REACT_APP_URI}student/detail/all/${ReactSession.get("student_id")}`)
                .then((res) => {
                    if (res.data.length > 4) {
                        console.log(res.data);
                        setIsAcademicCompleted(true);
                    }
                    else{
                        alert('Please Complete the Academic Details and confirm that all 5 subjects must be filled')
                        return;
                    }
                }).catch(err => {
                    console.log(err.message)
                })
        }catch (err){
            console.log(err.message);
        }

    }

    const [schools,setSchools]=useState([{}]);
    const getSchools=async ()=>{

        const allschools=await Axios.get(`${process.env.REACT_APP_URI}student/student/prev/school`).then((res)=>{
            // debugger
            setSchools(res.data);
            console.log(res);
        }).catch(err=>{
            console.log(err.message);
        });
        console.log(allschools);
    }

    let addMoreFields=()=>{
        setFormValues([...formValues,{
            standard_name:"",subject_name:"",
            subject_maxmarks:0,subject_marksobtained:0,subject_type:0
        }]);
        let num=elementItem+1;
        setElementItem(num);
    }
    const [buttonTitle,setButtonTitle]=useState('Submit Academic Details');
    const [isDisabled,setIsDisabled]=useState(false);
    const [subjecttypes,setSubjectTypes]=useState([{}]);
    const getSubjectType=async ()=>{
        const subject_types=await Axios.get(`${process.env.REACT_APP_URI}subject/subject-types`).then((res)=>{
            setSubjectTypes(res.data);
            console.log(res.data);
        }).catch(err=>{
            console.log(err.message);
        })
    }

    const nextPage=()=>{
        history.push('/student/applied/stream');
    }

    const posAcademicDetails=async (e)=>{
        alert('Adding Subject Details')
        e.preventDefault();
        // var school_name=document.getElementById("school_name").value;
        // console.log(formValues)

        const {student_id,standard_name,subject_name,
        subject_maxmarks,subject_marksobtained,subject_type,school_name}=academicDetails;

        const studentid=ReactSession.get("student_id");
        // const schoolid=ReactSession.get("prevschool_id");
        await formValues.forEach(item=>{
            item.standard_name='10';
            item.subject_maxmarks='100';
            item.school_name=schoolDetail.school_name;
            item.board_name=schoolDetail.board_name;
            item.pass_year=schoolDetail.pass_year;
            item.school_boardrollno=schoolDetail.school_boardrollno;
            item.school_exammode=schoolDetail.school_exammode;
            item.school_boardrollno=schoolDetail.school_boardrollno;
            item.school_rollcode=schoolDetail.school_rollcode;
            item.school_registrationno=schoolDetail.school_registrationno;
            item.school_religion=schoolDetail.school_religion;
            // console.log(item);
            if(item.standard_name!==""){
                console.log(item);
                const res=Axios.post(`${process.env.REACT_APP_URI}student/academic/marks`,{
                    student_id:studentid,
                    school_name:item.school_name,
                    standard_name:item.standard_name,
                    pass_year:item.pass_year,
                    // standard_name:10,
                    subject_name:item.subject_name,
                    subject_maxmarks:item.subject_maxmarks,
                    subject_marksobtained:item.subject_marksobtained,
                    subject_type:1,
                    board_name:item.board_name,
                    school_exammode:item.school_exammode,
                    school_boardrollno:item.school_boardrollno,
                    school_rollcode:item.school_rollcode,
                    school_registrationno:item.school_registrationno,
                    school_religion:item.school_religion,
                },{

                    headers:{
                        "Content-Type":"application/json",
                    },

                }).then((res)=>
                {
                    console.log(res);
                    setButtonTitle('Information Saved');
                    setIsDisabled(true);
                }).catch(err=>{
                    console.log(err);
                });
            }});

    }
    return(
        <>
            <ArrowHeader form="academic"  />
            <div className="container">
                <div className="row">
                    <div className="col-md-2">&nbsp;</div>
                    <div className="col-md-12">
                        <div className="card border-secondary mt-3 text-justify">
                            <div className="card-header"><h4 className="card-title"> Previous Academic Details</h4></div>
                            <div className="card-body">
                                <div className="container form-floating">
                                    <form method="POST" id="form-studentbasic" onSubmit={posAcademicDetails}>
                                        <div className="row border">
                                            <div className="col-md-6">
                                                <label className="mt-3">School Name</label>
                                                <input type="text" name="school_name" className="form-control"
                                                    // value={academicDetails.standard_name} onChange={e=>handleInputs(index,e)}
                                                       value={schoolDetail.school_name} onChange={handleInput}
                                                />
                                                {/*<select name="prevschool_id" className="form-control"*/}
                                                {/*        value={school}*/}
                                                {/*        onChange={handleSelect}*/}
                                                {/*>*/}
                                                {/*    <option value={0}>--select School--</option>*/}
                                                {/*    /!*<option value={-1}> Add New School Information </option>*!/*/}
                                                {/*    {*/}
                                                {/*        schools.map(item=>{*/}
                                                {/*            console.log(item)*/}
                                                {/*            return <option key={item.prevschool_id} value={item.prevschool_id}>{item.school_name}</option>*/}
                                                {/*        })*/}
                                                {/*    }*/}
                                                {/*</select>*/}
                                               </div>

                                        <div className="col-md-3">
                                            <label className="mt-3">Board Name</label>
                                            <select name="board_name" className="form-control"
                                                    value={schoolDetail.board_name} onChange={handleInput} required={true}>
                                                <option value="">--Select Board--</option>
                                                <option value="JAC">JAC</option>
                                                <option value="CBSE">CBSE</option>
                                                <option value="ICSE">ICSE</option>
                                                <option value="BSEB">BSEB</option>
                                                <option value="WBBSE">WBBSE </option>
                                                <option value="UPMSB">UPMSB </option>
                                                <option value="OTHERS">OTHER BOARD </option>
                                            </select>
                                            {/*<input type="text" name="board_name" className="form-control"*/}
                                            {/*    // value={academicDetails.standard_name} onChange={e=>handleInputs(index,e)}*/}
                                            {/*       value={academicDetails.board_name} onChange={handleInput}*/}
                                            {/*/>*/}

                                            </div>
                                            <div className="col-md-3">
                                                <label className="mt-3">Passing Year</label>
                                                <select name="pass_year" className="form-control"
                                                        value={schoolDetail.pass_year} onChange={handleInput} required={true}>
                                                    <option value="">--Select Pass Year--</option>
                                                    <option value="2024">2024</option>
                                                    <option value="2023">2023</option>
                                                    <option value="2022">2022</option>
                                                    <option value="2021">2021</option>
                                                    <option value="2020">2020</option>
                                                    <option value="2019">2019</option>
                                                    <option value="2018">2018</option>

                                                </select>

                                            </div>
                                            <div className="col-md-4">
                                                <label className="mt-3">Board Roll No.</label>
                                                <input type="text" name="school_boardrollno" className="form-control"
                                                    // value={academicDetails.standard_name} onChange={e=>handleInputs(index,e)}
                                                       value={schoolDetail.school_boardrollno} onChange={handleInput}
                                                />

                                            </div>
                                            <div className="col-md-4">
                                                <label className="mt-3">Roll Code</label>
                                                <input type="text" name="school_rollcode" className="form-control"
                                                    // value={academicDetails.standard_name} onChange={e=>handleInputs(index,e)}
                                                       value={schoolDetail.school_rollcode} onChange={handleInput}
                                                />

                                            </div>
                                            <div className="col-md-4">
                                                <label className="mt-3">Registration No.</label>
                                                <input type="text" name="school_registrationno" className="form-control"
                                                    // value={academicDetails.standard_name} onChange={e=>handleInputs(index,e)}
                                                       value={schoolDetail.school_registrationno} onChange={handleInput}
                                                />

                                            </div>
                                            <div className="col-md-6  mb-3">
                                                <label className="mt-3">Religion</label>
                                                <select name="school_religion" className="form-control"
                                                        value={schoolDetail.school_religion} onChange={handleInput}
                                                        required={true}
                                                >
                                                    <option value="">--select religion--</option>
                                                    <option value={'HINDUISM'}>HINDUISM</option>
                                                    <option value={'ISLAM'}>ISLAM</option>
                                                    <option value={'SIKHISM'}>SIKHISM</option>
                                                    <option value={'CHRISTIANITY'}>CHRISTIANITY</option>
                                                    <option value={'OTHERS'}>OTHERS</option>
                                                </select>

                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className="mt-3 ">Medium of Exam</label>
                                                {/*<input type="text" name="school_exammode" className="form-control"*/}
                                                {/*    // value={academicDetails.standard_name} onChange={e=>handleInputs(index,e)}*/}
                                                {/*       value={schoolDetail.school_exammode} onChange={handleInput}*/}
                                                {/*/>*/}
                                                <select name="school_exammode" className="form-control"
                                                        value={schoolDetail.school_exammode} onChange={handleInput}
                                                        required={true}
                                                >
                                                    <option value="">--select medium--</option>
                                                    <option value={'ENGLISH'}>ENGLISH</option>
                                                    <option value={'HINDI'}>HINDI</option>
                                                    <option value={'BANGLA'}>BANGLA</option>
                                                    <option value={'URDU'}>URDU</option>
                                                    <option value={'ORIYA'}>ORIYA</option>
                                                </select>

                                            </div>
                                    </div>

                                        {formValues.map((academicDetails,index)=>(
                                        <div className="row">

                                            <div className="col-md-3 ">
                                                <label className="mt-3">Exam Passed</label>
                                                <input type="number" name="standard_name" className="form-control"
                                                       // value={academicDetails.standard_name} onChange={e=>handleInputs(index,e)}
                                                       value={10}   readOnly={true}
                                                />

                                            </div>
                                            <div className="col-md-3 ">
                                                <label className="mt-3">Subject Name</label>
                                                <input type="text" name="subject_name" className="form-control" required
                                                       pattern="[a-zA-Z'-'\s]*"
                                                       value={academicDetails.subject_name} onChange={e=>handleInputs1(index,e)}
                                                />
                                            </div>
                                            <div className="col-md-2 ">
                                                <label className="mt-3">Max marks</label>
                                                <input type="number" name="subject_maxmarks" className="form-control"
                                                       min={0} max={100} readOnly={true}
                                                       value={100} onChange={e=>handleInputs(index,e)}
                                                />
                                            </div>
                                            <div className="col-md-2 ">
                                                <label className="mt-3">Marks Obtained</label>
                                                <input type="number" name="subject_marksobtained" className="form-control"
                                                       min={0} max={100} required
                                                       value={academicDetails.subject_marksobtained} onChange={e=>handleInputs(index,e)}
                                                />
                                            </div>
                                            {/*<div className="col-md-2 ">*/}
                                            {/*    <label className="mt-3">Subject Type</label>*/}
                                            {/*    <select name="subject_type" className="form-control" required*/}
                                            {/*           value={academicDetails.subject_type} onChange={e=>handleInputs(index,e)}*/}
                                            {/*    >*/}
                                            {/*        <option value={0}>--Select Subject Type--</option>*/}
                                            {/*        {*/}
                                            {/*            subjecttypes.map(item=>{*/}
                                            {/*                return <option key={item.subjecttype_id} value={item.subjecttype_id}>{item.subjecttype_name}</option>*/}
                                            {/*            })*/}
                                            {/*        }*/}

                                            {/*    </select>*/}

                                            {/*</div>*/}
                                        </div>
                                            )
                                            )}
                                        {
                                            elementItem<4?
                                                <button type="button" onClick={addMoreFields} className="btn btn-sm btn-primary mt-3 mx-2" >Add Subject Information</button>
                                                :
                                                <button type="button" onClick={addMoreFields} className="btn btn-sm btn-primary mt-3 mx-2" disabled={true}>Add Subject Information</button>
                                        }

                                        {
                                            elementItem<4?
                                                <button className="btn btn-sm btn-success mt-3 mx-2" disabled> Submit Academic Details</button>
                                                :
                                                <button type="submit" className="btn btn-sm btn-success mt-3 mx-2" disabled={isDisabled} > {buttonTitle} </button>
                                        }
                                        {
                                            isDisabled?
                                                <button onClick={nextPage} className="btn btn-sm btn-warning mt-3 mx-2" > Apply for Stream and College</button>
                                                :
                                                <p></p>
                                        }


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">&nbsp;</div>
                </div>
            </div>
        </>
    )
}

export default AcademicDetails;