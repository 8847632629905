import React, {useEffect, useState} from 'react';
import {ReactSession} from "react-client-session";
import Axios from "axios";
import dateFormat from "dateformat";

const Registration=()=>{
    useEffect(()=>{
        getDetails();
        getAcadDetails();
    },[])
    const [studentDetail,setStudentDetail]=useState([{}])
    const getDetails=async ()=>{
        const details=await Axios.get(`${process.env.REACT_APP_URI}student/print/student/registration/${ReactSession.get("student_id")}`)
            .then((res)=>{
                console.log(res.data)
                setStudentDetail(res.data);
            })
    }
    const [subjectDetail,setSubjectDetail]=useState({
        // group:[],core1:[],core2:[],optional1:[]
        group1:'',group2:'',core1:'',core2:'',optional1:''
    })
    const getAcadDetails=async ()=>{
        const details=await Axios.get(`${process.env.REACT_APP_URI}student/print/student/registration/acad/${ReactSession.get("student_id")}`)
            .then((res)=>{
                console.log(res.data)
                setSubjectDetail(res.data)
            })
    }

    const printDiv=()=> {
        // var printContents = document.getElementById('page1').innerHTML;
        // var originalContents = document.body.innerHTML;
        // document.body.innerHTML = printContents;
        // window.print();
        // document.body.innerHTML = originalContents;
        var printContents = document.getElementById('print').innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    const h6={
        fontWeight:'bold',
        fontSize:'11pt',
    }
    const hn={
        fontWeight:'normal',
        fontSize:'11pt',
    }
    const hb={
        fontWeight:'bold',
        fontSize:'11pt',
        borderBottom:'2px solid #000'
    }
    const ht={
        fontWeight:'normal',
        fontSize:'10pt',
        borderTop:'2px solid #9e9e9e'
    }
    const ht2={
        fontWeight:'normal',
        fontSize:'8pt',
        borderBottom:'2px solid #9e9e9e',
        maxWidth: '50%',
        minWidth: '50%'
    }

    const textnormal={
        fontWeight:'normal',
        fontSize:'11pt',
        // width:'25%'
    }
    const textbold={
        fontWeight:'bold',
        fontSize:'13pt',
    }
    const photo={
        border:'1px solid #000',
        maxHeight:'130px',
        minHeight:'130px',
        width:'auto'
    }
    const hl={
        fontWeight:'bold'
    }
    const nrml={
        fontWeight:'normal',
        fontSize:'10px'
    }
    const blankTD={
        minWidth: '100px'
    }
    const sign={
        border:'1px solid #000',
        maxWidth:'130px',
        minWidth:'130px',
        height:'auto'
    }
    const h5={
        fontWeight:'bold',
        fontSize:'16pt',
    }
    return(
        <>
            <button className={'btn btn-sm btn-primary justify-center mt-4'} type={'button'} onClick={printDiv} > <i className="fa fa-print"></i> Print Form </button>
            <div  id='print'>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h5 className={'h5 text-bold'} style={h5} >
                            JHARKHAND ACADEMIC COUNCIL, RANCHI
                        </h5>
                        <h6 className="h6" style={h6}>
                            STUDENT INFORMATION SHEET FOR INTERMEDIATE REGN., SESSION 2022-24 & CLASS XI EXAMINATION 2023
                        </h6>
                        <div style={hn}>
                            (Duly filled in form is to be submitted to the Institution by the Student for Online Registration)
                        </div>
                        <div style={hb}>
                            (To be filled using black ball point pen only) &nbsp;&nbsp; (For REGULAR Candidate only)
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <table>
                        <tr>
                            <td style={textnormal}>Code & Name of Institution :</td>
                            <td style={textbold}>42008, PBNJMI College Deoghar, Deoghar, Jharkhand</td>
                        </tr>
                        <tr>
                            <td style={textnormal}>1 Name of the Student :</td>
                            <td style={textbold}>{studentDetail[0].student_name}</td>
                        </tr>
                        <tr>
                            <td style={textnormal}>2 Date of Birth :</td>
                            <td style={textbold}>
                                {dateFormat(studentDetail[0].student_dob,"dd/mm/yyyy")}
                            </td>
                        </tr>
                        <tr>
                            <td style={textnormal}>3 Gender :</td>
                            <td style={textbold}>{studentDetail[0].student_gender}</td>
                        </tr>
                        <tr>
                            <td style={textnormal}>4 Divyang :</td>
                            <td style={textbold}>{studentDetail[0].student_pwd}</td>
                        </tr>
                        <tr>
                            <td style={textnormal}>5 Caste :</td>
                            <td style={textbold}>{studentDetail[0].category_name}</td>
                        </tr>
                        <tr>
                            <td style={textnormal}>6 Religion :</td>
                            <td style={textbold}>{studentDetail[0].student_religion}</td>
                        </tr>
                        <tr>
                            <td style={textnormal}>7 Nationality :</td>
                            <td style={textbold}>INDIAN</td>
                        </tr>
                        <tr>
                            <td style={textnormal}>8 Student Aadhar Number :</td>
                            <td style={textbold}>{studentDetail[0].student_aadhar}</td>
                        </tr>
                        <tr>
                            <td style={textnormal}>9 Area :</td>
                            <td style={textbold}>RURAL</td>
                        </tr>
                        <tr>
                            <td style={textnormal}>10 Mother's Name :</td>
                            <td style={textbold}>{studentDetail[0].student_mothername}</td>
                        </tr>
                        <tr>
                            <td style={textnormal}>11 Father's Name :</td>
                            <td style={textbold}>{studentDetail[0].student_fathername}</td>
                        </tr>
                        <tr>
                            <td style={textnormal}>12 Communication Address:</td>
                            <td style={textbold}>{studentDetail[0].student_address}</td>
                        </tr>
                        <tr>
                            <td style={textnormal}>13 State :</td>
                            <td style={textbold}>Jharkhand</td>
                        </tr>
                        <tr>
                            <td style={textnormal}>14 Contact (Mobile) No. :</td>
                            <td style={textbold}>{studentDetail[0].student_mobile}</td>
                        </tr>
                        <tr>
                            <td style={textnormal}>15 E-mail ID :</td>
                            <td style={textbold}>{studentDetail[0].student_email}</td>
                        </tr>
                        <tr>
                            <td style={textbold} colSpan={2}>16 Class X Passing Details :</td>
                        </tr>
                        <tr>
                            <td style={textnormal} >a) Name of Board/Council :</td>
                            <td style={textbold}>{studentDetail[0].board_name}</td>
                        </tr>
                        <tr>
                            <td style={textnormal} >b) Year of Passing :</td>
                            <td style={textbold}>{studentDetail[0].pass_year}</td>
                        </tr>
                        <tr>
                            <td style={textnormal} >c) Roll Code : </td>
                            <td style={textbold}>{studentDetail[0].school_rollcode}</td>
                        </tr>
                        <tr>
                            <td style={textnormal} >d) Roll Number : </td>
                            <td style={textbold}>{studentDetail[0].school_boardrollno}</td>
                        </tr>
                        <tr>
                            <td style={textnormal} >e) Registration Number : </td>
                            <td style={textbold}>{studentDetail[0].school_registrationno}</td>
                        </tr>
                        <tr>
                            <td style={textbold} colSpan={2}>17 Class X Passing Details :</td>
                        </tr>
                        <tr>
                            <td style={textnormal} >a) Class :</td>
                            <td style={textbold}>Class XI</td>
                        </tr>
                        <tr>
                            <td style={textnormal} >b) Date of Admission :</td>
                            <td style={textbold}>&nbsp;</td>
                        </tr>
                        <tr>
                            <td style={textnormal} >c) Section : </td>
                            <td style={textbold}>&nbsp;</td>
                        </tr>
                        <tr>
                            <td style={textnormal} >d) Class Roll Number : </td>
                            <td style={textbold}>{studentDetail[0].class_rollno}</td>
                        </tr>
                        <tr>
                            <td style={textnormal} >18 Medium of Examination: </td>
                            <td style={textbold}>ENGLISH / HINDI / BENGALI / URDU / URIYA</td>
                        </tr>
                        <tr>
                            <td style={textnormal} >19 Category </td>
                            <td style={textbold}>REGULAR</td>
                        </tr>
                        <tr>
                            <td style={textnormal} >20 Faculty: </td>
                            <td style={textbold}>{studentDetail[0].stream_name}</td>
                        </tr>
                        <tr>
                            <td style={textnormal} colSpan={2} >21  Subjects offered : </td>
                        </tr>
                        <tr>
                            <td style={textnormal} >Core: </td>
                            <td style={textbold}>{
                                    subjectDetail.core1
                            }</td>
                        </tr>
                        <tr>
                            <td style={textnormal} >Compulsory-1 : </td>
                            <td style={textbold}>{subjectDetail.group1}</td>
                        </tr>
                        <tr>
                            <td style={textnormal} >Compulsory-2 : </td>
                            <td style={textbold}>{subjectDetail.group2}</td>
                        </tr>
                        <tr>
                            <td style={textnormal} >Optional-1 : </td>
                            <td style={textbold}>{subjectDetail.core2}</td>
                        </tr>
                        <tr>
                            <td style={textnormal} >Additional : </td>
                            <td style={textbold}>{subjectDetail.optional1}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <table>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td style={textnormal} >
                                            {/*Student Photo*/}
                                            {
                                                studentDetail?
                                                    <img style={photo} src={`${process.env.REACT_APP_URI}images/photo/${studentDetail[0].photo}`}/>
                                                    :
                                                    ''
                                            }

                                        </td>
                                        <td style={textbold}>
                                            <div className={'text-right'}>
                                            {
                                                studentDetail?
                                                    <img style={sign} src={`${process.env.REACT_APP_URI}images/sign/${studentDetail[0].sign}`}/>
                                                    :''
                                            }<br/><br/>

                                                __________________________<br/>
                                                <span style={nrml}>Signature of Father / Mother</span>
                                            </div>


                                        </td>
                                        <td>&nbsp;</td>
                                    </tr>
                                </table>

                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={ht} className={'text-center'}>
                                (for Institution use only)
                            </td>
                        </tr>
                        <tr >
                            <td style={ht2}>
                                <br/>
                                System Generated UID No. :  _________________________________
                            </td>
                            <td style={ht2}>
                                <br/>
                                Entered By : _______________________________
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <span style={hl}>Note :</span>
                                <ol style={nrml}>
                                    <li>
                                        For column no. 3, 4, 5, 6, 7, 9, 16a, 17a, 18, 19 & 20 you are required to tick the applicable one.
                                    </li>
                                    <li>
                                        Mention Board/Council Name if option "OTHERS" has been selected in column 17a.
                                    </li>
                                    <li>
                                        For faculty-wise applicable subject combination kindly contact your institution.
                                    </li>
                                    <li>
                                        Photograph to be pasted should not be more than 3 months old. Photograph & Signature should not exceed the box area.
                                    </li>
                                    <li>
                                        Submission of document is mandatory in case of the following :
                                        <ol type={'a'}>
                                            <li>
                                                If you are a Divyang student - submit copy of Certificate issued by the Government/ Local Authority.
                                            </li>
                                            <li>
                                                If you belong to Caste other than "GENERAL" - submit copy of Certificate issued by the Government/ Local Authority.
                                            </li>
                                            <li>
                                                If you have passed Class X from a Board/Council other than JAC - submit copy of migration certificate issued by that Board/Council.
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <span style={hl}>Get printed copy of your checklist from school and look at your details very carefully. Inform HM immediately if there is any mistake.</span>
                                    </li>
                                    <li>
                                        <span style={hl}>No correction will be allowed once exam form is submitted online.</span>
                                    </li>
                                </ol>
                                    <span style={textnormal}>Application No.: <span style={textbold}>{
                                        studentDetail?studentDetail[0].application_no:''}</span> </span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            </div>

        </>
    )
}

export default Registration