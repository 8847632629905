import React, {useEffect, useState} from "react";
import IsAdmin from "../isAdmin";
import Axios from "axios";
import {useHistory} from "react-router-dom";
import {CSVLink,CSVDownload} from "react-csv";
import {ReactSession} from "react-client-session";

const ExplicitAdmission=()=>{
    const history=useHistory();
    ReactSession.setStoreType("localStorage");
    useEffect(()=>{
        const Role=ReactSession.get("role");
        if(Role!=="collegeadmin" && Role!=="mainadmin")
        {
            alert("Please login first");
            history.push('/admin')
        }
    },[])
    const [students,setStudents]=useState([{}]);
    const [student,setStudent]=useState({});
    const [appId,setAppID]=useState({});
    const [sas,setSAS]=useState([]);
    let eleName;
    let value;
    const handleInputs=(e)=>{
        eleName=e.target.name;
        value=e.target.value;
        setAppID({...appId,[eleName]:value});
        // console.log(appId)
    }
    const handleInputs1=(e)=>{
        eleName=e.target.name;
        value=e.target.value;
        setStudent({...student,[eleName]:value});
        console.log(student)
    }
    const searchApplication=async ()=>{
        alert(appId.application_no)
        const sa=await Axios.get(`${process.env.REACT_APP_URI}student/get/appID/details/${appId.application_no}`).then((res)=>{
            console.log(res.data[0])
            ReactSession.set("student_id",res.data[0].student_id)
            setSAS(res.data);
        }).catch(err=>{
            console.log(err.message);
        })
    }

    const updateAdmFee=async()=>{
        // alert(process.env.REACT_APP_URI)
        const isValid=window.confirm('Are you sure to update admission fee details?');
        // alert(isValid)
        if(isValid){
            const sa=await Axios.post(`${process.env.REACT_APP_URI}fee/update/fee/details`,
                {
                    student_id:ReactSession.get("student_id"),
                    feemaster_id:25,
                    paid_amount:student.paid_amount,
                    payment_status:'success',
                    description:'NPG',
                    stream_id:1,
                    college_id:2
                }).then((res)=>{
                console.log(res)
                if(res.data.duplicate)
                    alert(`Admission already has been taken by ${ReactSession.get('student_name')}.`)
                else
                    alert('Record Added Successfully.')
            }).catch(err=>{
                console.log(err);
            })
        }else {

            return;
        }
    }



    return(
        <>
            {/*Check Admin Role Here*/}
            {/*Todo Code to check the admin Role */}

            <div className="container">
                <div className="row">
                    <div className="col-md-2">&nbsp;</div>
                    <div className="col-md-8">
                        <div className="card border-secondary mt-3 text-justify">
                            <div className="card-header"><h4 className="card-title">Assign Class Roll number to Students</h4></div>
                            <div className="card-body">
                                <div className="container form-floating">
                                    {/*<form method="POST" id="form-stream">*/}
                                    <div className="row">
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" onChange={handleInputs}
                                                   placeholder="Application No." aria-label="Application No." name={"application_no"}
                                                   aria-describedby="button-addon2"/>
                                            <button className="btn btn-primary" type="button" onClick={searchApplication}
                                                    id="button-addon2"> <i className="fa fa-search"></i> Search
                                            </button>
                                        </div>
                                        <div className="col-md-12">
                                            <table className="table table-stripped">
                                                <tr>
                                                    <th>Application No</th>
                                                    <th>Student Name</th>
                                                    <th>Admission Fee</th>
                                                    <th>Action</th>
                                                </tr>
                                                {
                                                    sas.length>0?
                                                        //display details
                                                        <tr>
                                                            <td>{sas[0].application_no}</td>
                                                            <td>{sas[0].student_name}</td>
                                                            <td>
                                                                <input type="number" className="form-control" placeholder="Fee Paid" name="paid_amount" onChange={handleInputs1}/>
                                                            </td>
                                                            <td>
                                                                <button onClick={updateAdmFee} className={"btn btn-sm btn-outline-success"}
                                                                >
                                                                    <i className="fa fa-thumbs-up"></i>Update Adm Fee
                                                                </button>
                                                            </td>
                                                        </tr>

                                                        :
                                                        <tr>
                                                            <td colSpan={4}>No Record found</td>
                                                        </tr>
                                                    //no details
                                                }
                                            </table>
                                        </div>

                                    </div>
                                    {/*</form>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">&nbsp;</div>
                </div>
            </div>
        </>
    )
}

export default ExplicitAdmission;